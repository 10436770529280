import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Header from './pages/Header';
import LeftSide from './pages/LeftSide';
import Footer from './pages/Footer';
import Home from './pages/Home';
import Payslip from "./pages/Payslip";
import Contact from './pages/Contact';
import Calendar from './pages/Calendar';
import Profile from './pages/Profile';
import User from './pages/User';
import AdminLeave from './pages/AdminLeave';
import AdminAttendance from './pages/AdminAttendance';
import AdminPayroll from "./pages/AdminPayroll";
import AdminPayslip from "./pages/AdminPayslip";
import Login from "./pages/Login";

import AuthService from "./services/auth.service";
import IUser from "./types/user.type";
import EventBus from "./common/EventBus";
import Amercement from "./pages/Amercement";
import AdminSetting from "./pages/AdminSetting";
import Rules from "./pages/Rules";
import Lobby from "./pages/Lobby";

type Props = {};

type State = {
    isModerator: boolean,
    isAdmin: boolean,
    currentUser: IUser | undefined
}

class App extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.logOut = this.logOut.bind(this);

        this.state = {
            isModerator: false,
            isAdmin: false,
            currentUser: undefined,
        };
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
                currentUser: user,
                isModerator: user.roles == "ROLE_MODERATOR",
                isAdmin: user.roles == "ROLE_ADMIN",
            });
        } else {
            this.logOut();
        }

        EventBus.on("logout", this.logOut);
    }

    componentWillUnmount() {
        EventBus.remove("logout", this.logOut);
    }

    logOut() {
        AuthService.logout();
        this.setState({
            isModerator: false,
            isAdmin: false,
            currentUser: undefined,
        });
    }

    render() {
        const {currentUser, isAdmin} = this.state;
        // console.log(currentUser);
        return (
            <>
                {
                    currentUser ?
                        (currentUser.active == 1 ?
                            <>
                                <Header/>
                                <LeftSide/>
                                <div className="page-wrapper">
                                    <div className="page-titles2">
                                    </div>
                                    <BrowserRouter>
                                        <Routes>
                                            <Route path="/" element={<Home/>}/>
                                            <Route path="/home" element={<Home/>}/>
                                            <Route path="/payslip" element={<Payslip/>}/>
                                            <Route path="/calendar" element={<Calendar/>}/>
                                            <Route path="/contact" element={<Contact/>}/>
                                            <Route path="/profile" element={<Profile/>}/>
                                            <Route path="/user" element={<User/>}/>
                                            <Route path="/amercement" element={<Amercement/>}/>
                                            <Route path="/rules" element={<Rules/>}/>
                                            <Route path="*" element={<Home/>}/>
                                            {isAdmin && <Route path="admin.attendance" element={<AdminAttendance/>}/>}
                                            {isAdmin && <Route path="admin.leave" element={<AdminLeave/>}/>}
                                            {isAdmin && <Route path="admin.payroll" element={<AdminPayroll/>}/>}
                                            {isAdmin && <Route path="admin.payslip" element={<AdminPayslip/>}/>}
                                            {isAdmin && <Route path="admin.setting" element={<AdminSetting/>}/>}
                                        </Routes>
                                    </BrowserRouter>
                                    <Footer/>
                                </div>
                            </>
                            :
                            <>
                                <BrowserRouter>
                                    <Routes>
                                        <Route path="/" element={<Lobby/>}/>
                                        <Route path="/lobby" element={<Lobby/>}/>
                                        <Route path="*" element={<Lobby/>}/>
                                    </Routes>
                                </BrowserRouter>
                            </>)
                        :
                        <>
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/" element={<Login/>}/>
                                    <Route path="/login" element={<Login/>}/>
                                    <Route path="*" element={<Login/>}/>
                                </Routes>
                            </BrowserRouter>
                        </>
                }
            </>
        );
    }
}

export default App;
