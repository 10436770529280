import React from "react";
import {CredentialResponse, GoogleLogin, GoogleOAuthProvider} from '@react-oauth/google';
import AuthService from "../services/auth.service";
import IUser from "../types/user.type";
import {Config} from "../cfg/Config";
import jwt from 'jwt-decode';
import WorkingTime from "../types/workingtime.type";

interface RouterProps {
    history: string;
}

type Props = {};

type State = {
    isLoggedIn: boolean,
    currentUser: IUser,
    date: Date
};


export default class Login extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoggedIn: false,
            currentUser: {},
            date: new Date()
        };
    }

    currentMonth = new Date();

    componentDidMount = () => {
        setInterval(
            () => this.tick(),
            1000
        );
        WorkingTime.init(b => {
        });
    };

    tick = () => {
        try {
            this.setState({
                date: new Date()
            });
        } catch (e) {
        }
    };

    Clock = () => {
        let x = new Date();
        const x1 = ("0" + x.getHours()).slice(-2) + ":" + ("0" + x.getMinutes()).slice(-2) + ":" + ("0" + x.getSeconds()).slice(-2);
        const x2 = x.toLocaleDateString("en-US", {weekday: 'long', month: 'short', day: '2-digit'});
        return (
            <>
                <h1 className="card-title m-t-20" id='ct'>{x1}</h1>
                <h3 id='ct2'>{x2}</h3>
            </>
        );
    };

    /*private async verify(client_id: string, jwtToken: string) {
        const client = new OAuth2Client(client_id);
        const ticket = await client.verifyIdToken({
            idToken: jwtToken,
            audience: client_id,
        });
        const payload = ticket.getPayload();
        return payload;
    }*/

    login = (result: any, jwtToken: string) => {
        let userInfo = {
            name: result.name,
            email: encodeURIComponent(result.email),
            given_name: result.given_name,
            family_name: result.family_name,
            picture: encodeURIComponent(result.picture),
            access_token: jwtToken,
            aud: result.aud,
            sub: result.sub,
        };
        // console.log(userInfo);
        this.setState({
            isLoggedIn: true,
            currentUser: userInfo,
            date: new Date()
        });
        AuthService.login(userInfo).then(
            user => {
                if (user == null) {
                    AuthService.logout();
                    this.setState({
                        isLoggedIn: false,
                        currentUser: {},
                        date: new Date()
                    });
                    return;
                }
                window.location.reload();
                console.log("login success");
            },
            error => {
                this.setState({
                    isLoggedIn: false,
                    currentUser: {},
                    date: new Date()
                });
                window.location.reload();
                console.log("login error");
            }
        );
    }

    // Success Handler
    responseGoogleSuccess = (response: CredentialResponse) => {
        if (response == null) {
            return;
        }
        let result = jwt(response.credential!);
        this.login(result, response.credential!)
        // this.verify(response.credential!, response.clientId!).then(r => this.login(r, response.credential!));
    };

    // Error Handler
    responseGoogleError = () => {
        // console.log(response);
        console.log('Login Failed');
    };

    render() {
        return (
            <>
                <section id="wrapper">
                    <div className="login-register"
                         style={{backgroundImage: `url(${"assets/images/background/login-register.jpg"})`}}>
                        <div className="login-box card">
                            <div className="card-body">
                                <div className="col-xs-12 col-sm-12 col-md-12 m-t-10 text-center">
                                    <img src="assets/images/logo.jpg" alt="assets/images/logo.jpg"
                                         className="img-circle" width="150"/>
                                    {this.Clock()}
                                    <div className="text-center m-t-20 align-self-center">
                                        <GoogleOAuthProvider
                                            clientId={Config.GOOGLE_CLIENT_ID}>
                                            <GoogleLogin
                                                onSuccess={this.responseGoogleSuccess}
                                                onError={this.responseGoogleError}
                                                useOneTap
                                                ux_mode="popup"
                                            />
                                        </GoogleOAuthProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}