import React from "react";
import IUser from "../types/user.type";
import AuthService from "../services/auth.service";
import PayService from "../services/pay.service";
import IPayslip from "../types/payslip.type";
import IPayroll from "../types/payroll.type";
import IDeduction from "../types/deduction.type";
import {toast, ToastContainer} from "react-toastify";
import Swal from 'sweetalert2';

type Props = {
    selectMonth: Date
}

type State = {
    isModerator: boolean,
    isAdmin: boolean,
    currentUser: IUser,
    selectMonth: Date,
    payslips: IAllPayslip,
    newDeduction: IDeduction
}

interface IDate {
    date?: Date,
    holiday?: boolean,
    paid_holiday?: boolean
}

interface IAllPayslip {
    totalEarnings: number,
    totalDeduction: number,
    totalNetIncome: number,
    totalAmercement: number,
    totalCheckInLate: number,
    totalForgotCheckInOut: number,
    isApproved: boolean,
    allPayslips: IPayslip[],
}

export default class AdminPayslipComponent extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isModerator: false,
            isAdmin: false,
            currentUser: {} as IUser,
            selectMonth: this.props.selectMonth,
            payslips: {} as IAllPayslip,
            newDeduction: {} as IDeduction
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeMonth = this.handleChangeMonth.bind(this);
        this.handleChangeYear = this.handleChangeYear.bind(this);
        this.handleChangeMoney = this.handleChangeMoney.bind(this);
        this.handleChangeReason = this.handleChangeReason.bind(this);
        this.handleChangeIsBonus = this.handleChangeIsBonus.bind(this);
    }

    componentDidMount = () => {
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
                currentUser: user,
                isModerator: user.roles?.includes("ROLE_MODERATOR"),
                isAdmin: user.roles?.includes("ROLE_ADMIN"),
            });
        }
    };

    componentDidUpdate = () => {
        if (this.state.selectMonth != this.props.selectMonth) {
            this.setState({
                selectMonth: this.props.selectMonth,
                payslips: {} as IAllPayslip,
            });
            this.updateData();
        }
    };

    updateData() {
        PayService.getAllPayslips(this.props.selectMonth.getMonth() + 1, this.props.selectMonth.getFullYear()).then(
            (a: IAllPayslip) => {
                this.setState({
                    payslips: a
                });
            }
        );
    };

    CreateLink = (isAdmin: boolean, email: string, picture: string) => {
        if (isAdmin) {
            return (<a href={"/payslip?email=" + email}><img src={picture} alt="user" className="img-circle"
                                                             width="40"/></a>);
        } else {
            return (<img src={picture} alt="user" className="img-circle" width="40"/>);
        }
    };

    CurrencyFormat = (num: number) => {
        return new Intl.NumberFormat('vi-VN', {style: 'currency', currency: 'VND'}).format(num);
    };


    SalaryAdvance = (payslip: IPayslip, deduction: IDeduction) => {
        deduction.user_id = payslip.user?.id;
        deduction.email = payslip.user?.email;
        deduction.month = new Date().getUTCMonth() + 1;
        deduction.year = new Date().getUTCFullYear();
        deduction.is_bonus = 0;
        deduction.installment_id = -1;
        deduction.installment_month_number = -1;
        this.setState({
            newDeduction: deduction
        });
    };

    ShowActions = (payslip: IPayslip, deduction: IDeduction) => {
        return (<>
            {/*<button className="btn btn-sm btn-rounded btn-success"
                    onClick={() => this.ApproveRequest(payslip)}>Approve
            </button>
            <button className="btn btn-sm btn-rounded btn-outline-danger m-l-10"
                    onClick={() => this.ApproveRequest(payslip)}>Decline
            </button>*/}
            <button data-toggle="modal" data-target="#create-salary-advance-modal"
                    className="btn btn-sm btn-rounded btn-outline-info m-l-10"
                    onClick={() => this.SalaryAdvance(payslip, deduction)}>Deduction
            </button>
        </>);
    };

    ShowQRCodeAlert(url: string) {
        Swal.fire({
            imageUrl: url,
            imageWidth: 400,
            // imageHeight: 400,
            imageAlt: "Custom image",
            showConfirmButton: false,
        });
    }

    handleChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        let newDeduction = this.state.newDeduction;
        newDeduction.email = event.target.value;
        this.setState({
            newDeduction: newDeduction
        });
    }

    handleChangeMonth(event: React.ChangeEvent<HTMLInputElement>) {
        let newDeduction = this.state.newDeduction;
        newDeduction.month = Number(event.target.value);
        this.setState({
            newDeduction: newDeduction
        });
    }

    handleChangeYear(event: React.ChangeEvent<HTMLInputElement>) {
        let newDeduction = this.state.newDeduction;
        newDeduction.year = Number(event.target.value);
        this.setState({
            newDeduction: newDeduction
        });
    }

    handleChangeMoney(event: React.ChangeEvent<HTMLInputElement>) {
        let newDeduction = this.state.newDeduction;
        newDeduction.money = Number(event.target.value);
        this.setState({
            newDeduction: newDeduction
        });
    }

    handleChangeReason(event: React.ChangeEvent<HTMLInputElement>) {
        let newDeduction = this.state.newDeduction;
        newDeduction.reason = event.target.value;
        this.setState({
            newDeduction: newDeduction
        });
    }

    handleChangeIsBonus(event: React.ChangeEvent<HTMLSelectElement>) {
        let newDeduction = this.state.newDeduction;
        newDeduction.is_bonus = event.target.value == "bonus" ? 1 : 0;
        this.setState({
            newDeduction: newDeduction
        });
    }

    handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        const {newDeduction, currentUser} = this.state;
        if (newDeduction.money == null) {
            this.ShowToast("Money can not be empty!");
            return;
        }
        newDeduction.approve = 1;
        newDeduction.approver = currentUser.email;
        PayService.createDeduction(newDeduction).then(
            (result) => {
                if (result['code'] == 0) {
                    window.location.reload();
                } else {
                }
            });
    };

    ShowToast = (message: string) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    ShowHeader() {
        return (<tr>
            <th className="footable-visible footable-sortable"></th>
            <th className="footable-visible footable-sortable"> Name</th>
            <th className="footable-visible footable-sortable"> Salary</th>
            <th className="footable-visible footable-sortable"> Working Days</th>
            <th className="footable-visible footable-sortable"> Bonus</th>
            <th className="footable-visible footable-sortable"> Income</th>
            <th className="footable-visible footable-sortable"> Insurance</th>
            <th className="footable-visible footable-sortable"> Amercement</th>
            <th className="footable-visible footable-sortable"> Deductions</th>
            <th className="footable-visible footable-sortable"> NET Income</th>
            <th className="footable-visible footable-sortable"> Bank Info</th>
            <th className="footable-visible footable-sortable"></th>
        </tr>)
    };

    ShowTable() {
        const {payslips, isAdmin, newDeduction, selectMonth} = this.state;
        const p = payslips['allPayslips'];
        if (p != null) {
            return p.map((att) => (
                <tr key={att.user!.id}>
                    <td>{this.CreateLink(isAdmin, att.user!.email!, att.user!.picture!)}</td>
                    <td>{att.user!.name}</td>
                    <td>{this.CurrencyFormat(att.salary!)}</td>
                    <td>{Number(att.paid_working_days!) + Number(att.paid_leave!) + Number(att.annual_leave!)} / {att.total_working_days!}</td>
                    <td>{this.CurrencyFormat(att.bonus!)}</td>
                    <td className="text-bold-green">{this.CurrencyFormat(Number(att.earnings!))}</td>
                    <td>{this.CurrencyFormat(Number(att?.social_insurance!) + Number(att?.health_insurance!) + Number(att?.unemployment_insurance!))}</td>
                    <td>{this.CurrencyFormat(att.amercement!)}</td>
                    <td>{this.CurrencyFormat(att.others_deduction!)}</td>
                    <td className="text-bold-green">{this.CurrencyFormat(this.CalculateNetIncome(att))}</td>
                    <td>
                        <button className="btn btn-sm"
                                onClick={() => this.ShowQRCodeAlert("https://img.vietqr.io/image/" + att.user!.bank_name + "-" + att.user!.bank_account + "-print.png?amount=" + this.CalculateNetIncome(att) + "&addInfo=" + encodeURI("TERAS thanh toan luong T" + (selectMonth.getMonth() + 1) + " " + selectMonth.getFullYear()) + "&accountName=" + att.user!.name)}>
                            <img
                                src={"https://img.vietqr.io/image/" + att.user!.bank_name + "-" + att.user!.bank_account + "-qr_only.png"}
                                alt="qr_code" width="15" height="15"/> PAY
                        </button>
                        {/*{att.user!.bank_account}
                        <br/>{att.user!.bank_name}*/}
                    </td>
                    <td>{this.ShowActions(att, newDeduction)}</td>
                </tr>
            ))
        }
    };

    CalculateNetIncome(att: IPayslip) {
        return Number(att.earnings!) - Number(att.amercement!) - Number(att.others_deduction!) - Number(att?.social_insurance!) - Number(att?.health_insurance!) - Number(att?.unemployment_insurance!);
    }

    onApprove = () => {
        PayService.approvePayslip(this.state.currentUser.email!, this.props.selectMonth.getMonth() + 1, this.props.selectMonth.getFullYear()).then(
            response => {
                window.location.reload();
            }
        );
    };

    render() {
        const {payslips, newDeduction, selectMonth} = this.state;
        const now = new Date();
        let showApproveButton = !payslips.isApproved && selectMonth.getFullYear() == now.getFullYear() && selectMonth.getMonth() == now.getMonth() && now.getDate() >= 26;
        return (
            <>
                <div className="card-group">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/bills.png" alt="assets/images/bills.png"
                                         width="40"/></div>
                                <div>
                                    <h3 className="card-title">{this.CurrencyFormat(payslips.totalEarnings!)}</h3>
                                    <h6 className="card-subtitle">Total Earning</h6></div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/dollar.png"
                                         alt="assets/images/dollar.png" width="40"/></div>
                                <div>
                                    <h3 className="card-title">{this.CurrencyFormat(payslips.totalNetIncome)}</h3>
                                    <h6 className="card-subtitle">Total NET Income</h6></div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/chronometer.png"
                                         alt="assets/images/chronometer.png" width="40"/></div>
                                <div>
                                    <h3 className="card-title">{this.CurrencyFormat(payslips.totalAmercement)}</h3>
                                    <h6 className="card-subtitle">Total Amercement</h6></div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/sand-clock.png" alt="assets/images/sand-clock.png"
                                         width="40"/></div>
                                <div>
                                    <h3 className="card-title">{payslips.totalCheckInLate} min
                                        / {payslips.totalForgotCheckInOut}</h3>
                                    <h6 className="card-subtitle">Check In Late/Forgot</h6></div>
                            </div>
                        </div>
                    </div>
                    {showApproveButton && <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="align-self-center">
                                    <button className="btn-success" onClick={() => this.onApprove()}>Approve</button>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                <div className="table-responsive">
                    <table className="table m-b-0 toggle-arrow-tiny color-table muted-table">
                        <thead>
                        {this.ShowHeader()}
                        </thead>
                        <tbody>
                        {this.ShowTable()}
                        </tbody>
                    </table>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div id="create-salary-advance-modal" className="modal fade" role="dialog"
                     aria-labelledby="requestLeaveModal" aria-hidden="true">
                    <form className="form-horizontal form-material" onSubmit={this.handleSubmit}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Deduction/Bonus</h4>
                                    <button type="button" className="close" data-dismiss="modal"
                                            aria-hidden="true">×
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="col-md-12">Email</label>
                                        <div className="col-md-12 input-group">
                                            <div className="input-group-addon">$
                                            </div>
                                            <input type="text" value={newDeduction.email}
                                                   onChange={this.handleChangeEmail}
                                                   className="form-control form-control-line" name="email"
                                                   id="email"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Month</label>
                                        <div className="col-md-12 input-group">
                                            <div className="input-group-addon">$
                                            </div>
                                            <input type="text" value={newDeduction.month}
                                                   onChange={this.handleChangeMonth}
                                                   className="form-control form-control-line" name="month"
                                                   id="month"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Year</label>
                                        <div className="col-md-12 input-group">
                                            <div className="input-group-addon">$
                                            </div>
                                            <input type="text" value={newDeduction.year}
                                                   onChange={this.handleChangeYear}
                                                   className="form-control form-control-line" name="year"
                                                   id="year"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-md-12 input-group">
                                            <select className="form-control form-control-line" name="is_bonus"
                                                    id="is_bonus"
                                                    onChange={this.handleChangeIsBonus}>
                                                <option value="deduction"
                                                        selected={newDeduction.is_bonus == 0}>Deduction
                                                </option>
                                                <option value="bonus"
                                                        selected={newDeduction.is_bonus == 1}>Bonus
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Money</label>
                                        <div className="col-md-12 input-group">
                                            <div className="input-group-addon">$
                                            </div>
                                            <input type="text" value={newDeduction.money}
                                                   onChange={this.handleChangeMoney}
                                                   className="form-control form-control-line" name="money"
                                                   id="money"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Reason</label>
                                        <div className="col-md-12 input-group">
                                            <div className="input-group-addon">$
                                            </div>
                                            <input type="text" value={newDeduction.reason}
                                                   onChange={this.handleChangeReason}
                                                   className="form-control form-control-line" name="reason"
                                                   id="reason"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit"
                                            className="btn btn-danger waves-effect waves-light">Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}