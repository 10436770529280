import React from "react";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import IUser from "../types/user.type";

type Props = {};

type State = {
    allUsers: IUser[],
    isModerator: boolean,
    isAdmin: boolean,
    currentUser: IUser,
};

export default class Contact extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            allUsers: [] as IUser[],
            isModerator: false,
            isAdmin: false,
            currentUser: {} as IUser
        };
    }

    componentDidMount = () => {
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
                currentUser: user,
                isModerator: user.roles == "ROLE_MODERATOR",
                isAdmin: user.roles == "ROLE_ADMIN",
            });
        }
        UserService.getAllBasicUsers().then(
            (a: IUser[]) => {
                this.setState({
                    allUsers: a.filter(item => item.active == 1)
                })
            }
        );
    };
    /*ShowTable = () => {
        if (this.curAttendances != null) {
            return this.curAttendances.map((att, i) => (
                <tr>
                    <td> {moment(att.clockin).format("dddd, DD MMM")}</td>
                    <td><i className="mdi mdi-arrow-bottom-right text-purple"></i>
                        {this.showClockIn(att.clockin)}</td>
                    <td><i className="mdi mdi-arrow-top-right text-info"></i>
                        {this.showClockOut(att.clockout)}</td>
                    <td><span className="text-bold">{new Date(att.workinghr! * 1000).toISOString().substr(11, 5)} hr</span></td>
                </tr>
            ))
        }
    };*/

    CreateLink = (isAdmin: boolean, email: string, picture: string) => {
        if (isAdmin) {
            return (<a href={"/user?email=" + email}><img src={picture} alt="user"
                                                          className="img-circle img-responsive"/></a>);
        } else {
            return (<img src={picture} alt="user" className="img-circle img-responsive"/>);
        }
    };

    capitalize = (s: string) => {
        return s && s[0].toUpperCase() + s.slice(1);
    };

    render() {
        const {allUsers, isAdmin} = this.state;
        return (
            <div className="container-fluid">
                <div className="row">
                    {
                        allUsers.map((item, i) => (
                            <div className="col-md-6 col-lg-6 col-xlg-4">
                                <div className="card card-body">
                                    <div className="row">
                                        <div className="col-md-4 col-lg-3 text-center">
                                            {this.CreateLink(isAdmin, item.email!, item.picture!)}
                                        </div>
                                        <div className="col-md-6 col-lg-7">
                                            <h3 className="box-title">{item.name}</h3>
                                            <h5 className="card-title">{item.title} {item?.employee_type != null && item?.employee_type != 'official' ? (' - ' + this.capitalize(item?.employee_type!)) : ''}</h5>
                                            <div>
                                                {item.email}
                                                <br/>
                                                P: {item.phone}
                                            </div>
                                        </div>
                                        <div className="col-md-2 col-lg-2 text-center">
                                            <a href={"https://img.vietqr.io/image/" + item.bank_name + "-" + item.bank_account + "-print.png?accountName="+item.name}
                                               target="_blank">
                                                <img
                                                    src={"https://img.vietqr.io/image/" + item.bank_name + "-" + item.bank_account + "-qr_only.png"}
                                                    alt="qr_code" className="img-responsive"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
}