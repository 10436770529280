import React from "react";
import AuthService from "../services/auth.service";
import EventService from "../services/event.service";
import UserService from "../services/user.service";
import IUser from "../types/user.type";
import FullCalendar, {
    EventApi,
    DateSelectArg,
    EventClickArg,
    EventContentArg,
    formatDate,
    EventInput
} from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import {DateSpanApi} from "@fullcalendar/common";
import IEvent from "../types/event.type";
import moment from "moment";
import ILeave from "../types/leave.type";
import {start} from "repl";

type Props = {
    /*selectMonth: Date,
    email: string,*/
};

type State = {
    allUsers: IUser[],
    isModerator: boolean,
    isAdmin: boolean,
    email: string,
    selectMonth: Date,
    currentUser: IUser,
    showCreateEventModal: boolean,
    showEventModal: boolean,
    currentEvent: IEvent,
    allEvents: IEvent[],
    allEventOnline: IEvent[],
    allEventInputs: EventInput[],
    reqUserDone: boolean,
    reqEventDone: boolean,
};

export default class Calendar extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        const d = new Date();
        this.state = {
            allUsers: [] as IUser[],
            isModerator: false,
            isAdmin: false,
            selectMonth: new Date(),
            email: "",
            currentUser: {} as IUser,
            showCreateEventModal: false,
            showEventModal: false,
            currentEvent: {
                id: this.createEventId(),
                title: "",
                type: "weekend",
                color: "#FF0037",
                repeat_period: "none",
                all_day: 1,
                is_day_off: 1,
                start: d,
                end: d,
                start_str: moment(d).format("YYYY-MM-DD") + "T00:00",
                end_str: moment(d).format("YYYY-MM-DD") + "T23:59",
            } as IEvent,
            allEvents: [] as IEvent[],
            allEventOnline: [] as IEvent[],
            allEventInputs: [] as EventInput[],
            reqUserDone: false,
            reqEventDone: false,
        };

        this.handleChangeEventTitle = this.handleChangeEventTitle.bind(this);
        this.handleChangeEventType = this.handleChangeEventType.bind(this);
        this.handleChangeEventRepeat = this.handleChangeEventRepeat.bind(this);
        this.handleChangeEventDescription = this.handleChangeEventDescription.bind(this);
        this.handleChangeEventStartDate = this.handleChangeEventStartDate.bind(this);
        this.handleChangeEventEndDate = this.handleChangeEventEndDate.bind(this);
        this.handleChangeEventIsDayOff = this.handleChangeEventIsDayOff.bind(this);
        this.handleChangeEventIsCompanyEvent = this.handleChangeEventIsCompanyEvent.bind(this);
        this.handleChangeEventIsPaidHoliday = this.handleChangeEventIsPaidHoliday.bind(this);
        this.handleChangeEventColor = this.handleChangeEventColor.bind(this);
        this.handleNewEventSubmit = this.handleNewEventSubmit.bind(this);
    }

    componentDidMount = () => {
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
                currentUser: user,
                isModerator: user.roles == "ROLE_MODERATOR",
                isAdmin: user.roles == "ROLE_ADMIN",
            });
        }
        UserService.getAllBasicUsers().then(
            (a: IUser[]) => {
                this.setState({
                    allUsers: a.filter(item => item.active == 1),
                    reqUserDone: true,
                });
                if (this.state.reqEventDone) {
                    this.convertEvents();
                }
            }
        );
        EventService.getAllEvents(this.state.selectMonth.getMonth() + 1, this.state.selectMonth.getFullYear()).then(
            (a: IEvent[]) => {
                this.setState({
                    allEventOnline: a,
                    reqEventDone: true,
                });
                if (this.state.reqUserDone) {
                    this.convertEvents();
                }
            }
        );
    };

    CreateLink = (isAdmin: boolean, email: string, picture: string) => {
        if (isAdmin) {
            return (<a href={"/user?email=" + email}><img src={picture} alt="user"
                                                          className="img-circle img-responsive"/></a>);
        } else {
            return (<img src={picture} alt="user" className="img-circle img-responsive"/>);
        }
    };

    eventGuid = 0;
    fullCalendar = React.createRef<FullCalendar>();

    convertEvents = () => {
        const {allEventOnline, allUsers} = this.state;
        const events = [] as EventInput[];
        const allEvents = [] as IEvent[];
        this.fullCalendar.current?.getApi()?.removeAllEvents();
        allEventOnline.map(event => {
            allEvents.push(event);
        });
        allUsers.map(user => {
            if (user.birthday != null && user.birthday.trim() != '') {
                const today = new Date();
                const birthday = new Date(user.birthday.replace(/-/g, "/"));
                for (let i = -1; i < 2; i++) {
                    birthday.setFullYear(today.getFullYear() + i);
                    const e = {
                        id_str: user.email,
                        title: user.family_name + "'s birthday",
                        description: user.name + "'s birthday",
                        start_str: moment(birthday).format("YYYY-MM-DD") + "T00:00",
                        end_str: moment(birthday).format("YYYY-MM-DD") + "T23:59",
                        type: "birthday",
                        color: "rgb(188,0,107)",
                        repeat_period: "none",
                        all_day: 1,
                        is_day_off: 0,
                    } as IEvent;
                    allEvents.push(e);
                }
            }
        });
        allEvents.map(event => {
            const endDate = new Date(event.end_str!);
            endDate.setMinutes(endDate.getMinutes() + 1);
            const e = {
                id: event.id_str != null ? event.id_str : String(event.id),
                title: event.type == 'weekend' ? "Weekend" : event.title,
                description: event.description,
                start: event.start_str,
                end: moment(endDate).format("YYYY-MM-DD") + "T23:59",
                allDay: event.all_day == 1,
                backgroundColor: event.color,
            } as EventInput;
            /*if (event.is_company_event == 1 || (event.type != 'event' && event.type != 'meeting' && event.type != 'birthday' && event.all_day == 1)) {
                e.display = 'background';
            } else {*/
            e.display = 'auto';
            // }
            events.push(e);
        });
        const currentDate = this.fullCalendar.current?.getApi()?.getDate();
        const weekends = this.getWeekends(currentDate!.getMonth(), currentDate!.getFullYear());
        weekends.map(date => {
            /*let f = true;
            allEvents.map(event => {
                const startDate = new Date(event.start_str!);
                const endDate = new Date(event.end_str!);
                if (event.type != 'weekend' && startDate <= date && date <= endDate && event.type != 'event' && event.type != 'meeting' && event.type != 'birthday' && event.all_day == 1) {
                    f = false;
                }
            });*/
            // if (f) {
            const e = {
                id: String(this.createEventId()),
                title: "Weekend",
                description: "Weekend",
                start: moment(date).format("YYYY-MM-DD") + "T00:00",
                end: moment(date).format("YYYY-MM-DD") + "T23:59",
                allDay: true,
                backgroundColor: "#FF0037",
                display: 'background'
            } as EventInput;
            events.push(e);
            // }
        });
        this.setState({
            allEvents: allEvents,
            allEventInputs: events
        });
    };

    getWeekends = (month: number, year: number) => {
        var date = new Date(year, month, 1);
        var days = [];
        while (date.getMonth() === month) {
            let currentDate = new Date(date);
            let replaceWD = false;//currentDate.getFullYear() == 2022 && currentDate.getMonth() == 3 && currentDate.getDate() == 2;
            this.state.allEvents?.map(event => {
                const eDate = new Date(event.start_str!);
                if (event.type == "day_off" && event.is_day_off == 0 && currentDate.getFullYear() == eDate.getFullYear() && currentDate.getMonth() == eDate.getMonth() && currentDate.getDate() == eDate.getDate()) {
                    replaceWD = true;
                }
            });
            let weekNumber = Number.parseInt(moment(currentDate).format("w"));
            if (year == 2023)
                weekNumber += 1;
            const d = new Date(currentDate);
            d.setHours(0);
            d.setMinutes(0);
            d.setSeconds(0);
            if (currentDate.getDay() == 0 || (currentDate.getDay() == 6 && weekNumber % 2 == 0 && !replaceWD)) {
                days.push(d);
            }
            date.setDate(date.getDate() + 1);
        }
        return days;
    };

    feedEvents = (next: boolean) => {
        if (next) {
            this.fullCalendar.current?.getApi()?.next();
        } else {
            this.fullCalendar.current?.getApi()?.prev();
        }
        // alert(this.fullCalendar.current?.getApi()?.getDate());
        this.convertEvents();
    };

    createEventId() {
        return this.eventGuid++
    };

    handleDateSelect = (selectInfo: DateSelectArg) => {
        if (!this.state.isAdmin) {
            return;
        }
        const d = selectInfo?.end;
        d.setMinutes(d.getMinutes() - 1);
        const currentEvent = {
            id: this.createEventId(),
            title: "",
            type: "weekend",
            color: "#FF0037",
            repeat_period: "none",
            all_day: selectInfo?.allDay ? 1 : 0,
            is_day_off: 1,
            start: selectInfo?.start,
            end: d,
            start_str: selectInfo?.startStr + "T00:00",
            end_str: moment(d).format("YYYY-MM-DD") + "T23:59",
            view: selectInfo?.view
        } as IEvent;
        this.setState({
            currentEvent: currentEvent,
            showCreateEventModal: true
        });
    };

    addEvent = () => {
        const {currentEvent, currentUser} = this.state;
        // let calendarApi = currentEvent.view?.calendar;
        this.fullCalendar.current?.getApi()?.unselect();
        let event = {} as EventInput;
        const sd = new Date(currentEvent?.start_str!);
        const ed = new Date(currentEvent?.end_str!);
        if (currentEvent?.type != "day_off" || (sd.getDate() == ed.getDate() && sd.getMonth() == ed.getMonth() && sd.getFullYear() == ed.getFullYear())) {
            if (sd.getHours() > 0 || ed.getHours() < 23) {
                currentEvent.all_day = 0;
            }
            event = {
                id: String(currentEvent?.id),
                title: currentEvent?.title,
                start: currentEvent?.start_str,
                end: currentEvent?.end_str,
                allDay: currentEvent?.all_day,
                backgroundColor: currentEvent.color,
            } as EventInput;
        } else {
            const replaceWDEvent = {
                id: this.createEventId(),
                email: currentUser.email,
                title: "Làm bù ngày " + currentEvent.start_str?.replace("T00:00", ""),
                description: currentEvent.description,
                users: currentEvent.users,
                location: currentEvent.location,
                type: currentEvent.type,
                is_company_event: currentEvent.is_company_event,
                is_paid_holiday: currentEvent.is_paid_holiday,
                is_day_off: 0,
                start_str: currentEvent.end_str?.replace("T23:59", "T00:00"),
                end_str: currentEvent.end_str,
                all_day: 1,
                repeat_period: currentEvent.repeat_period,
                color: currentEvent.color
            } as IEvent;
            currentEvent.end_str = currentEvent.start_str?.replace("T00:00", "T23:59");
            currentEvent.is_day_off = 1;
            currentEvent.all_day = 1;
            event = {
                id: String(currentEvent?.id),
                title: currentEvent?.title,
                start: currentEvent?.start_str,
                end: currentEvent?.end_str,
                backgroundColor: currentEvent.color,
                allDay: true,
            } as EventInput;
            const event1 = {
                id: String(replaceWDEvent?.id),
                title: replaceWDEvent?.title,
                start: replaceWDEvent?.start_str,
                end: replaceWDEvent?.end_str,
                backgroundColor: replaceWDEvent.color,
                allDay: true,
            } as EventInput;
            this.fullCalendar.current?.getApi()?.addEvent(event1);
            EventService.create(currentUser, replaceWDEvent);
        }
        // console.log(event);
        if (currentEvent?.type == 'weekend') {
            event.display = 'background';
            event.backgroundColor = '#FF0037';
        }
        this.fullCalendar.current?.getApi()?.addEvent(event);
        EventService.create(currentUser, currentEvent);
        this.closeModal("add-new-event");
    };

    handleEventClick = (clickInfo: EventClickArg) => {
        if (!this.state.isAdmin) {
            return;
        }
        const {allEvents, allUsers} = this.state;
        let currentEvent = {} as IEvent;
        if (clickInfo?.event.id.indexOf("@") > -1) {
            for (let i = 0; i < allUsers.length; i++) {
                if (allUsers[i].email == clickInfo?.event.id) {
                    const birthday = new Date(allUsers[i].birthday!.replace(/-/g, "/"));
                    birthday.setFullYear(this.state.selectMonth.getFullYear());
                    currentEvent = {
                        id_str: allUsers[i].email,
                        title: allUsers[i].family_name,
                        description: allUsers[i].name,
                        start_str: moment(birthday).format("YYYY-MM-DD") + "T00:00",
                        end_str: moment(birthday).format("YYYY-MM-DD") + "T23:59",
                        type: "birthday",
                        color: "rgb(188,0,107)",
                        repeat_period: "none",
                        all_day: 1,
                        is_day_off: 0,
                    } as IEvent;
                    break;
                }
            }
        } else {
            const id = Number.parseInt(clickInfo?.event.id);
            for (let i = 0; i < allEvents.length; i++) {
                if (allEvents[i].id == id) {
                    currentEvent = allEvents[i];
                    break;
                }
            }
        }
        this.setState({
            currentEvent: currentEvent,
            showEventModal: true
        });
    };

    renderEventContent = (eventContent: EventContentArg) => {
        return (
            <>
                <b>{eventContent.timeText}</b>
                <i>{eventContent.event.title}</i>
            </>
        )
    };

    renderSidebarEvent = () => {
        const {allEvents} = this.state;
        const today = new Date();
        const next30Days = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 30);
        const events = allEvents.filter(event => {
            const e = new Date(event.start_str!);
            return event.type != 'weekend' && e >= today && e <= next30Days;
        }).sort((a, b) => {
            const e1 = new Date(a.start_str!);
            const e2 = new Date(b.start_str!);
            return e1 == e2 ? 0 : e1 > e2 ? 1 : -1;
        });
        return events.map((event: IEvent) => (
            <div className="card">
                <div className="card-body p-10">
                    <div className="d-flex">
                        <div className="m-r-20 align-self-center">
                            <img src={this.GetEventImage(event)} alt={this.GetEventImage(event)}
                                 width="40"/>
                        </div>
                        <div>
                            <h6 className="card-subtitle">{this.TimeFormat(event)}</h6>
                            <h4 className="card-title">{event.description == null || event.description == "" ? event.title : event.description}</h4>
                        </div>
                    </div>
                </div>
            </div>
        ));
    };

    TimeFormat = (event: IEvent) => {
        if (moment(event.start_str).format("DD MM YYYY") == moment(event.end_str).format("DD MM YYYY"))
            return <>{moment(event.start_str).format(event.all_day == 1 ? "ddd, MMM DD" : "ddd, MMM DD HH:mm")}</>;
        return <>{moment(event.start_str).format(event.all_day == 1 ? "ddd, MMM DD" : "ddd, MMM DD HH:mm")} - {moment(event.end_str).format(event.all_day == 1 ? "ddd, MMM DD" : "ddd, MMM DD HH:mm")}</>;
    };

    GetEventTitle = (event: IEvent) => {
        switch (event.type) {
            case 'weekend':
            case 'day_off':
                return <>Chilling Day</>;
            case 'holiday':
                return <>Happy Holiday</>;
            case 'birthday':
                return <>Happy Birthday</>;
            case 'team_building':
                return <>Team Building</>;
            default:
                return <>Event</>;
        }
    };

    GetEventImage = (event: IEvent) => {
        switch (event.type) {
            case 'weekend':
            case 'day_off':
                return this.state.currentUser.gender == 'Male' ? "assets/images/sunbathing_2.png" : "assets/images/sunbathing_3.png";
            case 'holiday':
                return "assets/images/vacation.png";
            case 'birthday':
                return "assets/images/birthday-cake.png";
            case 'team_building':
                return "assets/images/teambuilding.png";
            default:
                return "assets/images/calendar.png";
        }
    };

    showModal = (id: string) => {
        if (id == "add-new-event") {
            this.setState({
                showCreateEventModal: true
            });
        } else if (id == "my-event") {
            this.setState({
                showEventModal: true
            });
        }
    };

    closeModal = (id: string) => {
        if (id == "add-new-event") {
            this.setState({
                showCreateEventModal: false
            });
        } else if (id == "my-event") {
            this.setState({
                showEventModal: false
            });
        }
    };

    handleChangeEventTitle(event: React.ChangeEvent<HTMLInputElement>) {
        let newEvent = this.state.currentEvent;
        newEvent.title = event.target.value;
        this.setState({
            currentEvent: newEvent
        });
    }

    handleChangeEventDescription(event: React.ChangeEvent<HTMLInputElement>) {
        let newEvent = this.state.currentEvent;
        newEvent.description = event.target.value;
        this.setState({
            currentEvent: newEvent
        });
    }

    handleChangeEventStartDate(event: React.ChangeEvent<HTMLInputElement>) {
        let newEvent = this.state.currentEvent;
        newEvent.start_str = event.target.value;
        this.setState({
            currentEvent: newEvent
        });
    }

    handleChangeEventEndDate(event: React.ChangeEvent<HTMLInputElement>) {
        let newEvent = this.state.currentEvent;
        newEvent.end_str = event.target.value;
        this.setState({
            currentEvent: newEvent
        });
    }

    handleChangeEventType(event: React.ChangeEvent<HTMLSelectElement>) {
        let newEvent = this.state.currentEvent;
        newEvent.type = event.target.value;
        newEvent.is_day_off = newEvent.type == "weekend" ? 1 : 0;
        switch (newEvent.type) {
            case 'weekend':
            case 'day_off':
                newEvent.color = "#FF0037";
                break;
            case 'holiday':
                newEvent.color = "#1499ef";
                break;
            case 'birthday':
                newEvent.color = "#EF0CAE";
                break;
            case 'team_building':
                newEvent.color = "#EFC700";
                break;
            default:
                newEvent.color = "#ef7e15";
                break;
        }
        this.setState({
            currentEvent: newEvent
        });
    }

    handleChangeEventRepeat(event: React.ChangeEvent<HTMLSelectElement>) {
        let newEvent = this.state.currentEvent;
        newEvent.repeat_period = event.target.value;
        this.setState({
            currentEvent: newEvent
        });
    }

    handleChangeEventIsDayOff(event: React.ChangeEvent<HTMLInputElement>) {
        let newEvent = this.state.currentEvent;
        newEvent.is_day_off = event.target.checked ? 1 : 0;
        this.setState({
            currentEvent: newEvent
        });
    }

    handleChangeEventIsCompanyEvent(event: React.ChangeEvent<HTMLInputElement>) {
        let newEvent = this.state.currentEvent;
        newEvent.is_company_event = event.target.checked ? 1 : 0;
        this.setState({
            currentEvent: newEvent
        });
    }

    handleChangeEventIsPaidHoliday(event: React.ChangeEvent<HTMLInputElement>) {
        let newEvent = this.state.currentEvent;
        newEvent.is_paid_holiday = event.target.checked ? 1 : 0;
        this.setState({
            currentEvent: newEvent
        });
    }

    handleChangeEventColor(event: React.ChangeEvent<HTMLInputElement>) {
        let newEvent = this.state.currentEvent;
        newEvent.color = event.target.value;
        this.setState({
            currentEvent: newEvent
        });
    }

    handleNewEventSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        this.addEvent();
    };

    render() {
        const {showCreateEventModal, showEventModal, currentEvent, isAdmin, allEventInputs, allEvents} = this.state;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="">
                                <div className="row">
                                    <div className="col-md-3 p-l-0 p-r-0 bg-light-primary">
                                        <div className="card-body">
                                            <div className='demo-app-sidebar-section'>
                                                <h3>Upcoming events</h3>
                                                {this.renderSidebarEvent()}
                                            </div>
                                            {isAdmin &&
                                            <a href="#" onClick={() => this.showModal("add-new-event")}
                                               className="btn m-t-10 btn-info btn-block waves-effect waves-light">
                                                <i className="ti-plus"></i> Add New Event
                                            </a>}
                                        </div>
                                    </div>
                                    <div className="col-md-9 p-l-0">
                                        <div className="card-body b-l calender-sidebar">
                                            <FullCalendar
                                                plugins={[dayGridPlugin, timeGridPlugin, listPlugin, bootstrap5Plugin, interactionPlugin]}
                                                headerToolbar={{
                                                    left: 'prev',
                                                    center: 'title',
                                                    right: 'next'
                                                }}
                                                ref={this.fullCalendar}
                                                initialView='dayGridMonth'
                                                themeSystem={'bootstrap'}
                                                height={800}
                                                editable={false}
                                                selectable={true}
                                                droppable={false}
                                                selectMirror={true}
                                                dayMaxEvents={true}
                                                selectAllow={(span: DateSpanApi, movingEvent: EventApi | null) => {
                                                    return true;
                                                }}
                                                firstDay={1}
                                                slotDuration={"00:15:00"}
                                                // initialEvents={this.INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
                                                events={allEventInputs}
                                                weekends={true}
                                                select={this.handleDateSelect}
                                                eventContent={this.renderEventContent} // custom render function
                                                eventClick={this.handleEventClick}
                                                // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                                                eventAdd={function () {
                                                }}
                                                eventChange={function () {
                                                }}
                                                eventRemove={function () {
                                                }}
                                                customButtons={{
                                                    next: {
                                                        click: () => this.feedEvents(true)
                                                    },
                                                    prev: {
                                                        click: () => this.feedEvents(false)
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal none-border" id="my-event" style={{display: showEventModal ? "block" : "none"}}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title"><strong>{this.GetEventTitle(currentEvent)}</strong></h4>
                                <button type="button" className="close" data-dismiss="modal"
                                        onClick={() => this.closeModal("my-event")}
                                        aria-hidden="true">&times;</button>
                            </div>
                            <div className="modal-body">
                                {/*<label>{currentEvent?.description == null || currentEvent?.description == "" ? currentEvent?.title : currentEvent?.description}</label>*/}
                                {/*<label>{currentEvent?.description}</label>*/}
                                <div className="card-body p-10">
                                    <div className="d-flex">
                                        <div className="m-r-20 align-self-center">
                                            <img src={this.GetEventImage(currentEvent)}
                                                 alt={this.GetEventImage(currentEvent)}
                                                 width="40"/>
                                        </div>
                                        <div>
                                            <h4 className="card-title">{currentEvent.description == null || currentEvent.description == "" ? currentEvent.title : currentEvent.description}</h4>
                                            <h6 className="card-subtitle">{this.TimeFormat(currentEvent)}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal none-border" id="add-new-event"
                     style={{display: showCreateEventModal ? "block" : "none"}}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form role="form" onSubmit={this.handleNewEventSubmit}>
                                <div className="modal-header">
                                    <h4 className="modal-title"><strong>Create</strong> an event</h4>
                                    <button type="button" className="close" data-dismiss="modal"
                                            onClick={() => this.closeModal("add-new-event")}
                                            aria-hidden="true">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="col-md-12">Category</label>
                                        <div className="col-md-12 row">
                                            {<select className="form-control form-white col-md-9 m-l-15"
                                                     onChange={this.handleChangeEventType}
                                                     data-placeholder="Choose event type" name="event-type">
                                                isAdmin && <option value="weekend"
                                                                   selected={currentEvent.type == 'weekend'}>Weekend
                                            </option>
                                                <option value="holiday"
                                                        selected={currentEvent.type == 'holiday'}>Holiday
                                                </option>
                                                <option value="event"
                                                        selected={currentEvent.type == 'event'}>Event
                                                </option>
                                                <option value="meeting"
                                                        selected={currentEvent.type == 'meeting'}>Meeting
                                                </option>
                                                <option value="birthday"
                                                        selected={currentEvent.type == 'birthday'}>Birthday
                                                </option>
                                                isAdmin && <option value="team_building"
                                                                   selected={currentEvent.type == 'team_building'}>Team
                                                Building</option>
                                                isAdmin && <option value="day_off"
                                                                   selected={currentEvent.type == 'day_off'}>Day
                                                Off</option>
                                            </select>}

                                            <input className="form-control form-white col-md-2 m-l-15"
                                                   value={currentEvent.color} onChange={this.handleChangeEventColor}
                                                   type="color" name="event-color"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Title</label>
                                        <div className="col-md-12">
                                            <input className="form-control form-white"
                                                   value={currentEvent.title} onChange={this.handleChangeEventTitle}
                                                   type="text" name="event-title"/>
                                        </div>
                                    </div>
                                    {/*<div className="form-group">
                                        <label className="col-md-12">Description</label>
                                        <div className="col-md-12">
                                            <input className="form-control form-white"
                                                   value={currentEvent.description}
                                                   onChange={this.handleChangeEventDescription}
                                                   type="text" name="event-description"/>
                                        </div>
                                    </div>*/}
                                    <div className="form-group">
                                        {/*<div className="col-md-7">*/}
                                        <label className="col-md-12">Start</label>
                                        <div className="col-md-12">
                                            <input type="datetime-local" value={currentEvent.start_str}
                                                   onChange={this.handleChangeEventStartDate}
                                                   className="form-control form-control-line" name="event-start"
                                                   id="event-start"/>
                                        </div>
                                        {/*</div>*/}
                                        {/*<div className="col-md-4">
                                            <label>Repeat</label>
                                            <div>
                                                <select className="form-control form-white"
                                                        onChange={this.handleChangeEventRepeat}
                                                        data-placeholder="Repeat Period" name="event-repeat">
                                                    <option value="none"
                                                            selected={currentEvent.repeat_period == 'none'}>None
                                                    </option>
                                                    <option value="daily"
                                                            selected={currentEvent.repeat_period == 'daily'}>Daily
                                                    </option>
                                                    <option value="weekly"
                                                            selected={currentEvent.repeat_period == 'weekly'}>Weekly
                                                    </option>
                                                    <option value="monthly"
                                                            selected={currentEvent.repeat_period == 'monthly'}>Monthly
                                                    </option>
                                                    <option value="annually"
                                                            selected={currentEvent.repeat_period == 'annually'}>Annually
                                                    </option>
                                                </select>
                                            </div>
                                        </div>*/}
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">End</label>
                                        <div className="col-md-12">
                                            <input type="datetime-local" value={currentEvent.end_str}
                                                   onChange={this.handleChangeEventEndDate}
                                                   className="form-control form-control-line" name="event-end"
                                                   id="event-end"/>
                                        </div>
                                    </div>
                                    <div className="row m-l-15">
                                        {isAdmin && <div className="form-group m-l-15">
                                            <input type="checkbox" id="md_checkbox_35"
                                                   checked={currentEvent.is_day_off == 1}
                                                   onChange={this.handleChangeEventIsDayOff}
                                                   className="filled-in chk-col-deep-orange form-control"/>
                                            <label htmlFor="md_checkbox_35">Day off</label>
                                        </div>}
                                        {isAdmin && <div className="form-group m-l-15">
                                            <input type="checkbox" id="md_checkbox_36"
                                                   checked={currentEvent.is_company_event == 1}
                                                   onChange={this.handleChangeEventIsCompanyEvent}
                                                   className="filled-in chk-col-amber form-control"/>
                                            <label htmlFor="md_checkbox_36">Company Event</label>
                                        </div>}
                                        {isAdmin && <div className="form-group m-l-15">
                                            <input type="checkbox" id="md_checkbox_37"
                                                   checked={currentEvent.is_paid_holiday == 1}
                                                   onChange={this.handleChangeEventIsPaidHoliday}
                                                   className="filled-in chk-col-cyan form-control"/>
                                            <label htmlFor="md_checkbox_37">Paid Holiday</label>
                                        </div>}
                                    </div>
                                    {/*<div className="form-group m-l-15">
                                        <div className="row">
                                            <label className="col-md-2">Color</label>
                                            <div className="col-md-2">

                                            </div>
                                        </div>
                                    </div>*/}
                                </div>
                                <div className="modal-footer">
                                    <button type="submit"
                                            className="btn btn-danger waves-effect waves-light save-category"
                                            data-dismiss="modal">Save
                                    </button>
                                    {/*<button type="button" className="btn btn-white waves-effect"
                                            onClick={() => this.closeModal("add-new-event")}
                                            data-dismiss="modal">Close
                                    </button>*/}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}