import React from "react";
import IUser from "../types/user.type";
import AuthService from "../services/auth.service";
import AttendanceService from "../services/attendance.service";
import IAttendance from "../types/attendance.type";
import moment from "moment";
import ILeave from "../types/leave.type";
import LeaveService from "../services/leave.service";
import EventService from "../services/event.service";
import IEvent from "../types/event.type";
import WorkingTime from "../types/workingtime.type";

type Props = {
    selectMonth: Date,
    queryUser: IUser,
    time: string
}

type State = {
    isModerator: boolean,
    isAdmin: boolean,
    currentUser: IUser,
    selectDate: Date,
    queryUser: IUser,
    time: string,
    curAttendances: IAttendance[],
    avgClockIn: number,
    avgClockInStr: string,
    avgClockOut: number,
    avgClockOutStr: string,
    avgWorkingHr: number,
    avgWorkingHrStr: string,
    absent: number,
    leaves: number,
    allowIps: string[],
    leaveRequests: ILeave[],
    reqLeaveRequestsDone: boolean,
    reqAttendancesDone: boolean,
    allDates: IDate[],
    allEvents: IEvent[],
    reqEventDone: boolean,
}

interface IDate {
    date?: Date,
    attendance?: IAttendance,
    leaves?: ILeave[],
    holiday?: boolean,
    description?: string
}

export default class UserHome extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        const user = AuthService.getCurrentUser();
        this.state = {
            isModerator: false,
            isAdmin: false,
            currentUser: user,
            selectDate: this.props.selectMonth,
            queryUser: this.props.queryUser,
            time: this.props.time,
            curAttendances: [] as IAttendance[],
            avgClockIn: 0,
            avgClockInStr: "",
            avgClockOut: 0,
            avgClockOutStr: "",
            avgWorkingHr: 0,
            avgWorkingHrStr: "",
            absent: 0,
            leaves: 0,
            allowIps: [] as string[],
            leaveRequests: [] as ILeave[],
            reqLeaveRequestsDone: false,
            reqAttendancesDone: false,
            allDates: [] as IDate[],
            allEvents: [] as IEvent[],
            reqEventDone: false,
        };
    }

    sumClockIn = 0;
    sumClockOut = 0;
    sumWorkingHr = 0;
    countWorkingHr = 0;

    componentDidMount = () => {
        /*const user = AuthService.getCurrentUser();
        this.setState({
            currentUser: user,
            isModerator: user.roles!.includes("ROLE_MODERATOR"),
            isAdmin: user.roles!.includes("ROLE_ADMIN"),
        });*/

        AuthService.getIp().then(
            (result) => {
                this.setState({
                    allowIps: result['allow_ips'] as string[]
                })
            }
        );
    };

    componentDidUpdate = () => {
        if (this.state.selectDate != this.props.selectMonth || this.state.queryUser != this.props.queryUser) {
            const roles = this.state.currentUser?.roles == undefined ? "" : this.state.currentUser?.roles;
            this.setState({
                selectDate: this.props.selectMonth,
                queryUser: this.props.queryUser,
                curAttendances: [] as IAttendance[],
                avgClockIn: 0,
                avgClockOut: 0,
                avgWorkingHr: 0,
                absent: 0,
                leaves: 0,
                leaveRequests: [] as ILeave[],
                reqLeaveRequestsDone: false,
                reqAttendancesDone: false,
                isModerator: roles.includes("ROLE_MODERATOR"),
                isAdmin: roles.includes("ROLE_ADMIN"),
            });
            this.updateData();
        }
    };

    updateData() {
        /*const queryParams = new URLSearchParams(window.location.search);
        const email = queryParams.get("email");*/
        // console.log(this.props.queryUser);
        if (this.props.queryUser?.email == null)
            return;
        AttendanceService.getAttendances(this.props.queryUser?.email!, this.props.selectMonth.getMonth() + 1, this.props.selectMonth.getFullYear()).then(
            (a: IAttendance[]) => {
                this.setState({
                    reqAttendancesDone: true,
                    curAttendances: a
                });
                if (this.state.reqLeaveRequestsDone && this.state.reqEventDone) {
                    this.CalculateTime(a);
                }
            }
        );
        LeaveService.getLeaveRequests(this.props.queryUser?.email!, this.props.selectMonth.getMonth() + 1, this.props.selectMonth.getFullYear()).then(
            (a: ILeave[]) => {
                this.setState({
                    reqLeaveRequestsDone: true,
                    leaveRequests: a
                });
                if (this.state.reqAttendancesDone && this.state.reqEventDone) {
                    this.CalculateTime(this.state.curAttendances);
                }
            }
        );
        EventService.getAllEvents(this.props.selectMonth.getMonth() + 1, this.props.selectMonth.getFullYear()).then(
            (a: IEvent[]) => {
                this.setState({
                    allEvents: a,
                    reqEventDone: true,
                });
                if (this.state.reqAttendancesDone && this.state.reqLeaveRequestsDone) {
                    this.CalculateTime(this.state.curAttendances);
                }
            }
        );
    };

    CalculateTime(curAttendances: IAttendance[]) {
        this.setState({
            avgClockIn: 0,
            avgClockInStr: "",
            avgClockOut: 0,
            avgClockOutStr: "",
            avgWorkingHr: 0,
            avgWorkingHrStr: "",
            absent: 0,
            leaves: 0,
        });
        this.sumClockIn = 0;
        this.sumClockOut = 0;
        this.sumWorkingHr = 0;
        this.countWorkingHr = 0;
        let avgClockIn = 0;
        let avgClockOut = 0;
        let avgWorkingHr = 0;
        let leaves = 0;
        let attendances = 0;
        let holidays = 0;

        if (curAttendances != null && curAttendances.length > 0) {
            for (let i = 0; i < curAttendances.length; i++) {
                let att = curAttendances[i] as IAttendance;
                if (att == null) {
                    continue;
                }
                const timeIn = this.getTime(att.clockin!);
                this.sumClockIn += timeIn.hour * 3600 + timeIn.minute * 60 + timeIn.second;
                if (att.clockout != null && att.clockout.trim() != "" && att.clockout.trim().length != 0) {
                    const timeOut = this.getTime(att.clockout);
                    this.sumClockOut += timeOut.hour * 3600 + timeOut.minute * 60 + timeOut.second;
                }
                if (Number(att.workinghr!) > 0) {
                    this.sumWorkingHr += Number(att.workinghr!);
                    this.countWorkingHr++;
                }
            }
            if (this.countWorkingHr > 0) {
                avgWorkingHr = this.sumWorkingHr / this.countWorkingHr;
                avgClockOut = this.sumClockOut / this.countWorkingHr;
            }
            if (curAttendances.length > 0) {
                avgClockIn = this.sumClockIn / curAttendances.length;
            }
        }

        const now = new Date();
        now.setHours(23);
        now.setMinutes(59);
        now.setSeconds(59);
        let firstDay = new Date(2022, 1, 17);
        const joinDate = new Date((this.props.queryUser.date_of_joining == null || this.props.queryUser.date_of_joining?.trim() == '') ? firstDay.toLocaleString() : this.props.queryUser.date_of_joining?.trim());
        joinDate.setHours(0);
        joinDate.setMinutes(0);
        joinDate.setSeconds(0);
        firstDay = firstDay >= joinDate ? firstDay : joinDate;
        let fromDate = new Date(this.props.selectMonth.getFullYear(), this.props.selectMonth.getMonth() - 1, 26, 0, 0, 0);
        let toDate = new Date(this.props.selectMonth.getFullYear(), this.props.selectMonth.getMonth(), 25, 23, 59, 59);
        fromDate = firstDay >= fromDate ? firstDay : fromDate;
        toDate = now < toDate ? now : toDate;
        const allDates = this.getDates(fromDate, toDate);

        allDates.map(obj => {
            const requests = this.state.leaveRequests.filter((att) => {
                const start = moment(att.start);
                const end = moment(att.end);
                const d = moment(obj.date);
                return (start <= d && d <= end);
            });
            if (requests != null) {
                obj.leaves = requests;
                requests.map(request => {
                    if (request.type != "Forgot_Check_In_Out" && request.type != "Come_Late_Leave_Early") {
                        if (request.leave_time == "am" || request.leave_time == "pm") {
                            leaves += 0.5;
                        } else {
                            leaves++;
                        }
                    }
                });
            }
            let attendance = this.state.curAttendances.find((att) => {
                return (att.day == obj.date?.getDate() && att.month == (obj.date?.getMonth()! + 1) && att.year == obj.date?.getFullYear());
            });
            if (attendance != null) {
                obj.attendance = attendance;
                attendances++;
            }
            if (obj.holiday) {
                holidays++;
            }
        });
        const avgClockInStr = new Date(avgClockIn * 1000).toISOString().substr(11, 5);
        const avgClockOutStr = new Date(avgClockOut * 1000).toISOString().substr(11, 5);
        const avgWorkingHrStr = new Date(avgWorkingHr * 1000).toISOString().substr(11, 5);
        this.setState({
            avgClockIn: avgClockIn,
            avgClockInStr: avgClockInStr,
            avgClockOut: avgClockOut,
            avgClockOutStr: avgClockOutStr,
            avgWorkingHr: avgWorkingHr,
            avgWorkingHrStr: avgWorkingHrStr,
            leaves: leaves,
            absent: Math.max(0, this.state.allDates.length - leaves - attendances - holidays),
            allDates: allDates
        });
    }

    getDates = (startDate: Date, endDate: Date) => {
        const dateArray = [] as IDate[];
        let currentDate = startDate;
        while (currentDate <= endDate) {
            let replaceWD = false;
            this.state.allEvents?.map(event => {
                const eDate = new Date(event.start_str!);
                if (event.type == "day_off" && event.is_day_off == 0 && currentDate.getFullYear() == eDate.getFullYear() && currentDate.getMonth() == eDate.getMonth() && currentDate.getDate() == eDate.getDate()) {
                    replaceWD = true;
                }
            });
            let weekNumber = Number.parseInt(moment(currentDate).format("w"));
            if (currentDate.getFullYear() == 2023)
                weekNumber += 1;
            const d = new Date(currentDate);
            let isHoliday = currentDate.getDay() == 0 || (currentDate.getDay() == 6 && weekNumber % 2 == 0 && !replaceWD);
            let description = isHoliday ? "   Do nothing and Chill" : "";
            this.state.allEvents?.map(event => {
                if (event.is_day_off == 1) {
                    const startDate = new Date(event.start_str!);
                    const endDate = new Date(event.end_str!);
                    if (startDate <= d && d <= endDate) {
                        isHoliday = true;
                        description = "   " + event.title!;
                    }
                }
            });
            d.setHours(0);
            d.setMinutes(0);
            d.setSeconds(0);
            dateArray.push({
                date: d,
                holiday: isHoliday,
                description: description,
                leaves: [] as ILeave[]
            } as IDate);
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dateArray.reverse();
    };

    getTime(time: string) {
        const dt = new Date(time!.replace(/-/g, "/"));
        return {
            hour: dt.getHours(),
            minute: dt.getMinutes(),
            second: dt.getSeconds(),
            time: moment(dt).format("HH:mm"),
            date: dt
        };
    };

    CreateLink = (isAdmin: boolean, email: string, picture: string) => {
        if (isAdmin) {
            return (<a href={"/user?email=" + email}><img src={picture} alt="user" className="img-circle"
                                                          width="40"/></a>);
        } else {
            return (<img src={picture} alt="user" className="img-circle" width="40"/>);
        }
    };

    ShowLeaveType = (type: string) => {
        switch (type) {
            case 'Casual':
                return (<div><img src="assets/images/calendar.png" width="20"/> <span
                    className="text-danger">{type!.replaceAll('_', ' ')}</span></div>);
            case 'Sick':
                return (<div><img
                    src={this.props.queryUser.gender == 'Male' ? "assets/images/sick-m.png" : "assets/images/sick-fm.png"}
                    width="20"/><span className="text-danger"> {type!.replaceAll('_', ' ')}</span></div>);
            case 'Annual':
                return (<div><img src="assets/images/calendar.png" width="20"/> <span
                    className="text-blue">{type!.replaceAll('_', ' ')}</span></div>);
            case 'Forgot_Check_In_Out':
                return (<div><img src="assets/images/late_2.png" width="20"/> <span
                    className="text-warning">{type!.replaceAll('_', ' ')}</span></div>);
            case 'Come_Late_Leave_Early':
                return (<div><img src="assets/images/late_2.png" width="20"/> <span
                    className="text-success">{type!.replaceAll('_', ' ')}</span></div>);
            case 'Work_From_Home':
                return (<div><img src="assets/images/wfh.png" width="20"/> <span
                    className="text-purple"> {type!.replaceAll('_', ' ')}</span></div>);
        }
    };

    ShowStatus = (leave: ILeave) => {
        if (leave.approve == 0) {
            return (<span className="label label-danger font-weight-100">Declined</span>);
        } else if (leave.approve == 1) {
            return (<span className="label label-success font-weight-100">Approved</span>);
        } else {
            return (<span className="label label-warning font-weight-100">Awaiting</span>);
        }
    };

    ShowActions = (leave: ILeave) => {
        if (leave.approve == -1) {
            return (<>
                <button className="btn btn-sm btn-rounded btn-success"
                        onClick={() => this.ApproveRequest(leave, true)}>Approve
                </button>
                <button className="btn btn-sm btn-rounded btn-danger m-l-10"
                        onClick={() => this.ApproveRequest(leave, false)}>Decline
                </button>
            </>);
        } else {
            return (<span>by {leave.approver}</span>);
        }
    };

    ApproveRequest = (leave: ILeave, approve: boolean) => {
        LeaveService.approve(leave.id!, this.state.currentUser.email, approve ? 1 : 0).then(
            (result) => {
                window.location.reload();
                console.log("user.home ApproveRequest");
            }
        );
    };

    ShowLeaveTime = (leave: ILeave) => {
        /*if (leave.type != "Work_From_Home") {
            return (<span className="text-danger">{leave.reason}</span>);
        }*/
        switch (leave.leave_time) {
            case 'am':
                return (<span className="text-default">Morning</span>);
            case 'pm':
                return (<span className="text-purple">Afternoon</span>);
            case 'fd':
                return (<span className="text-success">Full Day</span>);
            case 'md':
                return (<span
                    className="text-warning">{moment(leave.end).diff(leave.start, 'days') + 1} Days</span>);
        }
    };

    ShowHeader() {
        return (<tr>
            <th data-toggle="true"
                className="footable-visible footable-first-column footable-sortable"> Date
            </th>
            <th className="footable-visible footable-sortable"> Clock In</th>
            <th className="footable-visible footable-sortable"> Clock Out</th>
            <th className="footable-visible footable-sortable"></th>
            <th data-hide="phone"
                className="footable-visible footable-last-column footable-sortable"> Working
                Hr's
            </th>
        </tr>)
    }

    ShowLeaves(leaves: ILeave[], d: Date | null) {
        return leaves?.map(request => (
            <tr>
                {d != null && <td> {moment(d).format("ddd, MMM DD")}</td>}
                <td>{this.ShowLeaveType(request?.type!)}</td>
                <td>{this.ShowLeaveTime(request)}</td>
                <td><span className="text-danger">{request.reason}</span></td>
                <td>{this.ShowStatus(request)}</td>
            </tr>
        ));
    }

    ShowTable() {
        const {allDates, queryUser, reqLeaveRequestsDone, reqAttendancesDone} = this.state;
        if (!reqLeaveRequestsDone || !reqAttendancesDone) {
            return <></>;
        }
        return allDates.map((d, i) => (
            d.holiday ?
                <tr style={{backgroundColor: "rgba(32,34,251,0.1)"}}>
                    <td className="text-purple"> {moment(d.date).format("ddd, MMM DD")}</td>
                    <td colSpan={4}><img
                        src={queryUser.gender == 'Male' ? "assets/images/sunbathing_2.png" : "assets/images/sunbathing_3.png"}
                        width="35"/>
                        <span className="text-purple">{d.description}</span></td>
                </tr>
                :
                d.attendance != null ?
                    (d.leaves?.length! > 0 ?
                        <>
                            <tr>
                                <td rowSpan={1 + d.leaves?.length!}> {moment(d.date).format("ddd, MMM DD")}</td>
                                <td><i className="mdi mdi-arrow-bottom-right text-purple"></i>
                                    {this.showClockIn(d.attendance?.clockin, d.leaves)}</td>
                                <td colSpan={2}><i className="mdi mdi-arrow-top-right text-info"></i>
                                    {this.showClockOut(d.attendance?.clockout, d.attendance?.workinghr!, d.leaves)}</td>
                                <td><span
                                    className="text-bold">{new Date(d.attendance?.workinghr! * 1000).toISOString().substr(11, 5)} hr</span>
                                </td>
                            </tr>
                            {this.ShowLeaves(d.leaves!, null)}
                        </>
                        :
                        <tr>
                            <td> {moment(d.date).format("ddd, MMM DD")}</td>
                            <td><i className="mdi mdi-arrow-bottom-right text-purple"></i>
                                {this.showClockIn(d.attendance?.clockin, null)}</td>
                            <td colSpan={2}><i className="mdi mdi-arrow-top-right text-info"></i>
                                {this.showClockOut(d.attendance?.clockout, d.attendance?.workinghr!, null)}</td>
                            <td><span
                                className="text-bold">{new Date(d.attendance?.workinghr! * 1000).toISOString().substr(11, 5)} hr</span>
                            </td>
                        </tr>)
                    :
                    d.leaves?.length! > 0 ?
                        <>
                            {this.ShowLeaves(d.leaves!, d.date!)}
                        </>
                        :
                        <tr style={{backgroundColor: "rgba(251,58,58,0.13)"}}>
                            <td className="text-danger"> {moment(d.date).format("ddd, MMM DD")}</td>
                            <td colSpan={4}><span className="text-bold-red">Absent</span></td>
                        </tr>
        ))
    };

    showClockIn(time: string | undefined, leaves: ILeave[] | undefined | null) {
        let clockIn = this.getTime(time!);
        let wtConfig = WorkingTime.getConfig(clockIn.date);
        const clockInInMinute = clockIn.hour * 60 + clockIn.minute;
        let checkInTime = wtConfig.getMorningTime()[0] * 60 + wtConfig.getMorningTime()[1];
        if (leaves != null && leaves != undefined && leaves.length > 0) {
            leaves.map(leave => {
                if (leave.type == "Casual" || leave.type == "Sick" || leave.type == "Annual" || leave.type == "Work_From_Home") {
                    if (leave.leave_time == "am") {
                        checkInTime = wtConfig.getAfternoonTime()[0] * 60 + wtConfig.getAfternoonTime()[1];
                    }
                }
                if ((leave.type == "Come_Late_Leave_Early" || leave.type == "Forgot_Check_In_Out") && leave.approve == 1) {
                    if (leave.leave_time == "am") {
                        checkInTime = clockInInMinute + 1;
                    }
                }
            });
        }
        if (clockInInMinute > checkInTime) {
            return (<span className="text-bold-red"> {clockIn.time}</span>);
        } else {
            return (<span className="text-bold-green"> {clockIn.time}</span>);
        }
    };

    showClockOut(time: string | undefined, workinghr: number, leaves: ILeave[] | undefined | null) {
        if (time != null && time.trim() != "" && time.trim().length > 0) {
            let clockOut = this.getTime(time);
            let wtConfig = WorkingTime.getConfig(clockOut.date);
            let working_hour_times = wtConfig.getWorkingHour();
            let wht = working_hour_times.get(-1)!;
            let w = clockOut.date.getDay();
            working_hour_times.forEach((value: number, key: number) => {
                if (w == key) {
                    wht = value;
                    return;
                }
            });
            let requireWorkingHr_full = wht * 60; //clockOut.date.getDay() == 6 ? 6 * 60 * 60 : 8 * 60 * 60;
            let requireWorkingHr_half = wht / 2 * 60; //clockOut.date.getDay() == 6 ? 3 * 60 * 60 : 4 * 60 * 60;
            let workingHr_full = requireWorkingHr_full;
            if (leaves != null && leaves != undefined && leaves.length > 0) {
                leaves.map(leave => {
                    if (leave.type == "Casual" || leave.type == "Sick" || leave.type == "Annual" || leave.type == "Work_From_Home") {
                        if (leave.leave_time == "am") {
                            workingHr_full = requireWorkingHr_half;
                        }
                        if (leave.leave_time == "pm") {
                            workingHr_full = requireWorkingHr_half - 60;
                        }
                    }
                    if (leave.type == "Come_Late_Leave_Early" && leave.leave_time == "pm" && workingHr_full > 5 * 60 * 60) {
                        workingHr_full = requireWorkingHr_full - 60;
                    }
                });
            }
            if (workinghr < workingHr_full) {
                return (<span className="text-bold-red">{clockOut.time}</span>);
            } else {
                return (<span className="text-bold-green">{clockOut.time}</span>);
            }
        } else {
            return (<span></span>);
        }
    }

    showIpClockIn(ip: string) {
        if (this.state.allowIps.indexOf(ip) < 0) {
            return (<span className="text-bold-red"> {ip}</span>);
        } else {
            return (<span className="text-bold-green"> {ip}</span>);
        }
    };

    render() {
        return (
            <>
                <div className="card-group bg-light-warning p-l-20 p-r-20">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/clockin.png" alt="assets/images/clockin.png"
                                         width="40"/></div>
                                <div>
                                    <h3 className="card-title">{this.state.avgClockInStr}</h3>
                                    <h6 className="card-subtitle">Avg Clock In</h6></div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/clockout.png"
                                         alt="assets/images/clockout.png" width="40"/></div>
                                <div>
                                    <h3 className="card-title">{this.state.avgClockOutStr}</h3>
                                    <h6 className="card-subtitle">Avg Clock Out</h6></div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/workinghr.png"
                                         alt="assets/images/workinghr.png" width="40"/></div>
                                <div>
                                    <h3 className="card-title">{this.state.avgWorkingHrStr}</h3>
                                    <h6 className="card-subtitle">Avg Working Hr</h6></div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/absent.png" alt="assets/images/absent.png"
                                         width="40"/></div>
                                <div>
                                    <h3 className="card-title">{this.state.absent} / {this.state.leaves}</h3>
                                    <h6 className="card-subtitle">Absent / Leaves</h6></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table m-b-0 toggle-arrow-tiny color-table muted-table">
                        <thead>
                        {this.ShowHeader()}
                        </thead>
                        <tbody>
                        {this.ShowTable()}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}