import React from "react";
import AuthService from "../services/auth.service";
import IUser from "../types/user.type";
import UserHome from "../component/user.home";
import AttendanceService from "../services/attendance.service";
import IAttendance from "../types/attendance.type";
import UserLeave from "../component/user.leave";
import UserService from "../services/user.service";
import IResponse from "../types/response.type";
import WorkingTime from "../types/workingtime.type";

type Props = {};

type State = {
    isModerator: boolean,
    isAdmin: boolean,
    currentUser: IUser,
    selectMonth: Date,
    date: Date,
    ipv4: string,
    allowIps: string[],
    atWork: boolean,
    curAttendance: IAttendance,
    queryAttendanceDone: boolean,
    queryIpsDone: boolean,
    getConfigDone: boolean,
}

export default class Home extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        const user = AuthService.getCurrentUser();
        this.state = {
            isModerator: false,
            isAdmin: false,
            currentUser: user,
            selectMonth: this.GetStartMonth(user),
            date: new Date(),
            ipv4: '',
            allowIps: [],
            atWork: false,
            curAttendance: {} as IAttendance,
            queryAttendanceDone: false,
            queryIpsDone: false,
            getConfigDone: false,
        };
    }

    componentDidMount = () => {
        const user = AuthService.getCurrentUser();
        // const selectMonth = this.getSelectMonth();
        if (user.email != null && user.email != "") {
            UserService.getUser(user.email!).then((u: IUser) => {
                if (u != null && u.active == 1) {
                    this.setState({
                        currentUser: u,
                        selectMonth: this.GetStartMonth(u),
                        isModerator: user.roles == "ROLE_MODERATOR",
                        isAdmin: user.roles == "ROLE_ADMIN",
                    });
                    localStorage.setItem("user", JSON.stringify(u));
                    // console.log(this.state.currentUser);
                } else {
                    AuthService.logout();
                    this.setState({
                        currentUser: {}
                    });
                }
            });
        } else {
            AuthService.logout();
            this.setState({
                currentUser: {}
            });
            window.location.reload();
            return;
        }
        WorkingTime.init(b => {
            this.setState({
                getConfigDone: true,
            });
        });
        setInterval(
            () => this.tick(),
            1000
        );
        let now = new Date();
        if (!this.state.queryAttendanceDone) {
            AttendanceService.getAttendance(user?.email, now.getDate(), now.getMonth() + 1, now.getFullYear()).then(
                (a: IAttendance) => {
                    this.setState({
                        curAttendance: a,
                        queryAttendanceDone: true,
                    });
                    // console.log("home get attendance");
                    if (this.state.queryIpsDone && this.state.atWork && a == null) {
                        this.checkin(true);
                    }
                }
            );
        }
        this.getIP();
    };

    tick = () => {
        this.setState({
            date: new Date()
        });
    };

    Clock = () => {
        try {
            let x = new Date();
            let x1 = "00:00";
            const wk = this.getWorkingHr();
            const x2 = x.toLocaleDateString("en-US", {weekday: 'short', month: 'short', day: '2-digit'});
            if (wk > 0) {
                return (
                    <div>
                        <div>
                            <h1 id='ct'><span
                                className="text-green"> {new Date(wk * 1000).toISOString().substr(11, 8)}</span></h1>
                            <h4 id='ct2'>{x2}</h4>
                            <>{this.getClockMessage()}</>
                        </div>
                    </div>
                );
            } else {
                x1 = ("0" + x.getHours()).slice(-2) + ":" + ("0" + x.getMinutes()).slice(-2) + ":" + ("0" + x.getSeconds()).slice(-2);
                return (
                    <div>
                        <div>
                            <h1 id='ct'>{x1}</h1>
                            <h4 id='ct2'>{x2}</h4>
                            <>{this.getClockMessage()}</>
                        </div>
                    </div>
                );
            }
        } catch (e) {
        }
    };

    getClockMessage = () => {
        let x = new Date();
        let wtConfig = WorkingTime.getCurrentConfig();
        let lunchTime = new Date(x.getFullYear(), x.getMonth(), x.getDate(), wtConfig.getLunchTime()[0], wtConfig.getLunchTime()[1], 0);
        let afternoonTime = new Date(x.getFullYear(), x.getMonth(), x.getDate(), wtConfig.getAfternoonTime()[0], wtConfig.getAfternoonTime()[1], 0);
        if (lunchTime <= x && x < afternoonTime) {
            return <h5 style={{color: 'green', fontWeight: "bold"}}>Happy Lunch</h5>;
        }
        if (this.state.atWork) {
            return <h5 style={{color: 'green', fontWeight: "bold"}}>Happy Working Day</h5>;
        }
        return <h5 style={{color: 'red', fontWeight: "bold"}}>Outside the Office</h5>;
    };

    getWorkingHr = () => {
        const {curAttendance} = this.state;
        let x = new Date();
        let wtConfig = WorkingTime.getCurrentConfig();
        let lunchTime = new Date(x.getFullYear(), x.getMonth(), x.getDate(), wtConfig.getLunchTime()[0], wtConfig.getLunchTime()[1], 0);
        let afternoonTime = new Date(x.getFullYear(), x.getMonth(), x.getDate(), wtConfig.getAfternoonTime()[0], wtConfig.getAfternoonTime()[1], 0);
        let morningWorkingHr = 0;
        let afternoonWorkingHr = 0;
        if (curAttendance != null && curAttendance.clockin != null && curAttendance.clockin.trim() != "" && curAttendance.clockin.trim().length > 0) {
            const clockin = new Date(curAttendance.clockin!.replace(/-/g, "/"));
            if (clockin < lunchTime) {
                if (x < lunchTime) {
                    morningWorkingHr = (x.getTime() - clockin.getTime()) / 1000;
                } else {
                    morningWorkingHr = (lunchTime.getTime() - clockin.getTime()) / 1000;
                }
            }
            if (clockin > afternoonTime)
                afternoonTime = clockin;
            if (x > afternoonTime) {
                afternoonWorkingHr = (x.getTime() - afternoonTime.getTime()) / 1000;
                if (curAttendance.clockout != null && curAttendance.clockout.trim() != "" && curAttendance.clockout.trim().length > 0) {
                    const clockout = new Date(curAttendance.clockout!.replace(/-/g, "/"));
                    afternoonWorkingHr = (clockout.getTime() - afternoonTime.getTime()) / 1000;
                }
            }
        }
        return morningWorkingHr + afternoonWorkingHr;
    };

    getIP = async () => {
        if (!this.state.queryIpsDone) {
            AuthService.getIp().then(
                (result) => {
                    const ip = result['ip'];
                    const allowIps = result['allow_ips'] as string[];
                    const atWork = allowIps.indexOf(ip) > -1;
                    this.setState({
                        ipv4: ip,
                        allowIps: allowIps,
                        atWork: atWork,
                        queryIpsDone: true
                    });
                    // console.log("home getIP");
                    if (atWork && this.state.queryAttendanceDone && this.state.curAttendance == null) {
                        this.checkin(true);
                    }
                }
            );
        }
    };

    checkin = (isAuto: boolean) => {
        const {currentUser} = this.state;
        let x = new Date();
        let wtConfig = WorkingTime.getCurrentConfig();
        let h = wtConfig.getFlexibleCheckin().length > 1 ? wtConfig.getFlexibleCheckin()[0] : 8;
        let m = wtConfig.getFlexibleCheckin().length > 1 ? wtConfig.getFlexibleCheckin()[1] : 0;
        if (x.getHours() < h && x.getMinutes() < m) {
            return;
        }
        AttendanceService.checkIn(currentUser?.email, isAuto).then(
            (result: IResponse) => {
                if (result.code == 0) {
                    window.location.reload();
                }
                // console.log("home check-in");
            }
        );
    };

    checkout = () => {
        if (!this.state.atWork) {
            alert("Connect to company network first!");
            return;
        }
        const {currentUser} = this.state;
        AttendanceService.checkOut(currentUser?.email, this.getWorkingHr()).then(
            () => {
                window.location.reload();
                // console.log("home check-out");
            }
        );
    };

    DateHeader = () => {
        let {selectMonth} = this.state;
        return (
            selectMonth.toLocaleDateString("en-US", {month: 'long', year: 'numeric'})
        );
    };

    GetThisMonth = () => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getDate() > 25 ? (now.getMonth() + 1) : now.getMonth(), 1);
    };

    GetStartMonth = (queryUser: IUser) => {
        if (queryUser != null && queryUser.resign_date != null) {
            const resign = new Date(queryUser.resign_date.replace(/-/g, "/"));
            resign.setDate(1);
            return resign;
        }
        return this.GetThisMonth();
    };

    btn_prev_month = () => {
        const {selectMonth, currentUser} = this.state;
        if (selectMonth.getFullYear() == 2022 && selectMonth.getMonth() <= 2)
            return;
        if (currentUser.date_of_joining != null) {
            const join = new Date(currentUser.date_of_joining.replace(/-/g, "/"));
            if (join.getDate() > 25)
                join.setMonth(join.getMonth() + 1);
            if (join >= selectMonth)
                return;
        }
        let a = new Date(selectMonth.getFullYear(), selectMonth.getMonth() - 1, 1);
        this.setState({
            selectMonth: a
        });
        this.DateHeader();
    };

    btn_next_month = () => {
        const {selectMonth, currentUser} = this.state;
        if (selectMonth >= this.GetThisMonth())
            return;
        if (currentUser.resign_date != null) {
            const resign = new Date(currentUser.resign_date.replace(/-/g, "/"));
            resign.setMonth(resign.getMonth() - 1);
            resign.setDate(1);
            if (selectMonth > resign)
                return;
        }
        let a = new Date(selectMonth.getFullYear(), selectMonth.getMonth() + 1, 1);
        this.setState({
            selectMonth: a
        });
        this.DateHeader();
    };

    ShowButton = () => {
        const {curAttendance, queryAttendanceDone} = this.state;
        if (!queryAttendanceDone) {
            return <></>;
        }
        if (curAttendance == null) {
            return (<button type="button" onClick={() => this.checkin(false)}
                            className="m-t-10 m-b-20 waves-effect waves-dark btn btn-primary btn-md btn-rounded">Check
                In</button>);
        } else if (curAttendance.clockout == null || curAttendance.clockout.trim() == "" || curAttendance.clockout.trim().length == 0) {
            return (<button type="button" data-toggle="modal" data-target="#checkout-modal"
                            className="m-t-10 m-b-20 waves-effect waves-dark btn btn-danger btn-md btn-rounded">Check
                Out</button>);
        } else {
            return (<span className="m-t-10 m-b-20 btn-md label label-success label-rounded">Thankyou</span>);
        }
    };

    render() {
        const {currentUser, selectMonth} = this.state;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-xlg-3 col-md-5">
                        <div className="card">
                            <div className="m-t-30 text-center">
                                <img src={currentUser?.picture} alt={currentUser?.picture} className="img-circle"
                                     width="150"/>
                                <h4 className="card-title m-t-10">{currentUser?.name}</h4>
                                <h5 className="card-subtitle">{currentUser?.title}</h5>
                            </div>
                            <div>
                                <hr/>
                            </div>
                            <div className="m-t-10 m-b-10 text-center">
                                {this.Clock()}
                                {this.ShowButton()}
                                <div id="checkout-modal" className="modal fade" role="dialog"
                                     aria-labelledby="checkOutModal" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title">Check Out</h4>
                                                <button type="button" className="close" data-dismiss="modal"
                                                        aria-hidden="true">×
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <span>Bạn chắc chắn muốn Check out?</span>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" onClick={this.checkout}
                                                        className="btn btn-danger waves-effect waves-light">Check Out
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <UserLeave selectMonth={selectMonth} email={currentUser.email!}/>
                    </div>
                    <div className="col-lg-8 col-xlg-9 col-md-7">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="bg-light-warning p-l-20 p-r-20">
                                    <div className="fc-toolbar fc-header-toolbar">
                                        <div className="fc-left">
                                            <button type="button"
                                                    className="fc-prev-button fc-button fc-state-default fc-corner-left"
                                                    onClick={this.btn_prev_month}><span
                                                className="mdi mdi-chevron-left"></span></button>
                                        </div>
                                        <div className="fc-center">
                                            <h2 id="date_header" className="text-info2">{this.DateHeader()}</h2>
                                        </div>
                                        <div className="fc-right">
                                            <button type="button"
                                                    className="fc-next-button fc-button fc-state-default fc-corner-right"
                                                    onClick={this.btn_next_month}><span
                                                className="mdi mdi-chevron-right"></span></button>
                                        </div>
                                        <div className="fc-clear"></div>
                                    </div>
                                </div>
                                <UserHome selectMonth={selectMonth} queryUser={currentUser} time={"month"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}