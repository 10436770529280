import axios from 'axios';
import {Config} from "../cfg/Config";
import DateTimeUtil from "../common/DateTimeUtil";

const qs = require('qs');

class LeaveService {
    request(email: string, type: string, start: string, leave_time: string, end: string, reason: string, annual_leaves: number) {
        return axios
            .post(Config.API_URL + "leave.php", qs.stringify({
                action: "request",
                email: email,
                type: type,
                start: start,
                leave_time: leave_time,
                end: end,
                reason: reason,
                annual_leaves: annual_leaves,
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    approve(id: number, approver: string | undefined, approve: number) {
        return axios
            .post(Config.API_URL + "leave.php", qs.stringify({
                action: "approve",
                id: id,
                approve: approve,
                approver: approver,
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    update(email: string, id: number, type: string, start: string, leave_time: string, end: string, reason: string, annual_leaves: number) {
        return axios
            .post(Config.API_URL + "leave.php", qs.stringify({
                action: "update",
                id: id,
                email: email,
                type: type,
                start: start,
                leave_time: leave_time,
                end: end,
                reason: reason,
                annual_leaves: annual_leaves,
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    delete(id: number) {
        return axios
            .post(Config.API_URL + "leave.php", qs.stringify({
                action: "delete",
                id: id,
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getLeaveRequests(email: string, month: number, year: number) {
        let fromDate = new Date(year, month - 1, 26);
        let toDate = new Date(year, month, 25);
        return axios
            .post(Config.API_URL + "leave.php", qs.stringify({
                action: "multi_range",
                email: email,
                from_day: 26,
                from_month: ("0" + DateTimeUtil.getMonth(fromDate.getMonth(), fromDate.getFullYear())).slice(-2),
                from_year: DateTimeUtil.getYear(fromDate.getMonth(), fromDate.getFullYear()),
                to_day: 25,
                to_month: ("0" + DateTimeUtil.getMonth(toDate.getMonth(), toDate.getFullYear())).slice(-2),
                to_year: DateTimeUtil.getYear(toDate.getMonth(), toDate.getFullYear())
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getAllLeaveRequestsByDay(day: number, month: number, year: number) {
        /*let fromDate = new Date(year, month, day);
        let toDate = new Date(year, month, day);*/
        return axios
            .post(Config.API_URL + "leave.php", qs.stringify({
                action: "all_range",
                from_day: day < 10 ? "0" + day : day,
                from_month: month < 10 ? "0" + month : month,
                from_year: year,
                to_day: day < 10 ? "0" + day : day,
                to_month: month < 10 ? "0" + month : month,
                to_year: year
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getAllLeaveRequests(month: number, year: number) {
        let fromDate = new Date(year, month - 1, 26);
        let toDate = new Date(year, month, 25);
        return axios
            .post(Config.API_URL + "leave.php", qs.stringify({
                action: "all_range",
                from_day: 26,
                from_month: ("0" + DateTimeUtil.getMonth(fromDate.getMonth(), fromDate.getFullYear())).slice(-2),
                from_year: DateTimeUtil.getYear(fromDate.getMonth(), fromDate.getFullYear()),
                to_day: 25,
                to_month: ("0" + DateTimeUtil.getMonth(toDate.getMonth(), toDate.getFullYear())).slice(-2),
                to_year: DateTimeUtil.getYear(toDate.getMonth(), toDate.getFullYear())
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }
}

export default new LeaveService();