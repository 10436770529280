import AuthService from "../services/auth.service";

interface IConfig {
    flexible_checkin?: string,
    start_date?: string,
    end_date?: string,
    morning_time?: string,
    lunch_time?: string,
    afternoon_time?: string,
    saturday_off?: number,
    working_hour?: string[]
}

interface ITimeConfig {
    active: string,
    time_configs: string[]
}

interface Dictionary<T> {
    [Key: string]: T;
}

class WTConfig {
    config: IConfig;

    constructor(config: IConfig) {
        this.config = config;
    }

    getFlexibleCheckin(): number[] {
        return this.getTime(this.config?.flexible_checkin);
    }

    getMorningTime(): number[] {
        return this.getTime(this.config?.morning_time);
    }

    getLunchTime(): number[] {
        return this.getTime(this.config?.lunch_time);
    }

    getAfternoonTime(): number[] {
        return this.getTime(this.config?.afternoon_time);
    }

    getWorkingHour(): Map<number, number> {
        let myMap = new Map<number, number>();
        Object.entries(this.config?.working_hour!).forEach(([key, value]) => {
            myMap.set(parseInt(key), parseInt(value));
        });
        return myMap;
    }

    IsSaturdayOff(): boolean {
        return this.config?.saturday_off == -1;
    }

    getTime(time?: string): number[] {
        if (!time) return [];
        let strings = time.split(':');
        return strings.map(function (x) {
            return parseInt(x);
        });
    }
}
type Callback = (n: boolean) => void;
class WorkingTime {
    timeConfig: ITimeConfig;
    workingConfig: Dictionary<WTConfig> = {};
    callback: Callback;

    constructor() {
        AuthService.getConfig().then(
            (result) => {
                this.timeConfig = result['config'];
                for (let config of this.timeConfig.time_configs) {
                    this.workingConfig[config] = new WTConfig(result[config]);
                }
                this.callback(true);
            }
        );
    }

    init(callback: Callback) {
        this.callback = callback;
    }

    getCurrentConfig(): WTConfig {
        return this.workingConfig[this.timeConfig?.active];
    }

    getConfig(date: Date): WTConfig {
        for (let configId of this.timeConfig?.time_configs) {
            let config = this.workingConfig[configId];
            if (!config.config?.start_date) continue;
            let startDate = new Date(config.config?.start_date + " 00:00:00");
            if (!config.config?.end_date) {
                if (startDate <= date) return config;
                continue;
            }
            let endDate = new Date(config.config?.end_date + " 23:59:59");
            if (startDate <= date && date <= endDate) return config;
        }
        return this.getCurrentConfig();
    }

    getAllConfig(): Dictionary<WTConfig> {
        return this.workingConfig;
    }
}

export default new WorkingTime();