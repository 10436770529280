import axios from 'axios';
import IUser from "../types/user.type";
import {Config} from "../cfg/Config";

const qs = require('qs');

class UserService {
    getUser(email: string) {
        return axios
            .post(Config.API_URL + "user.php", qs.stringify({
                action: "one",
                email: email
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getAllUsers() {
        return axios
            .post(Config.API_URL + "user.php", qs.stringify({
                action: "all"
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getAllBasicUsers() {
        return axios
            .post(Config.API_URL + "user.php", qs.stringify({
                action: "all_basic"
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    updateProfile(userInfo: IUser) {
        return axios
            .post(Config.API_URL + "user.php", "action=update&" + qs.stringify(userInfo), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    removeUser(userInfo: IUser) {
        return axios
            .post(Config.API_URL + "user.php", qs.stringify({
                action: "remove",
                email: userInfo.email
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }
}

export default new UserService();