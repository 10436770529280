import React from "react";
import AuthService from "../services/auth.service";
import IUser from "../types/user.type";
import {useNavigate} from "react-router";
import UserLeave from "../component/user.leave";

type Props = {};

type State = {
    isModerator: boolean,
    isAdmin: boolean,
    currentUser: IUser,
    selectMonth: Date
}

export default class AdminLeave extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isModerator: false,
            isAdmin: false,
            currentUser: {} as IUser,
            selectMonth: this.GetThisMonth()
        };
    }

    componentDidMount = () => {
        const user = AuthService.getCurrentUser();
        if (user == null || user.roles != "ROLE_ADMIN") {
            window.location.replace("/");
            return;
        }
        if (user) {
            this.setState({
                currentUser: user,
                selectMonth: this.GetThisMonth(),
                isModerator: user.roles == "ROLE_MODERATOR",
                isAdmin: user.roles == "ROLE_ADMIN",
            });
        }
    };

    DateHeader = () => {
        let {selectMonth} = this.state;
        // console.log(selectMonth);
        /*let a = new Date(selectMonth.getFullYear(), selectMonth.getMonth(), 1);
        this.setState({
            selectMonth: a
        });*/
        return (
            selectMonth.toLocaleDateString("en-US", {month: 'long', year: 'numeric'})
        );
    };

    GetThisMonth = () => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getDate() > 25 ? (now.getMonth() + 1) : now.getMonth(), 1);
    };

    btn_prev_month = () => {
        const {selectMonth} = this.state;
        if (selectMonth.getFullYear() == 2022 && selectMonth.getMonth() <= 2)
            return;
        let a = new Date(selectMonth.getFullYear(), selectMonth.getMonth() - 1, 1);
        if (a.getDay() == 0) {
            a = new Date(a.getFullYear(), a.getMonth(), a.getDate() - 1);
        }
        localStorage.setItem("selectMonth", JSON.stringify(a));
        this.setState({
            selectMonth: a
        });
        this.DateHeader();
    };

    btn_next_month = () => {
        const {selectMonth} = this.state;
        if (selectMonth > this.GetThisMonth())
            return;
        let a = new Date(selectMonth.getFullYear(), selectMonth.getMonth() + 1, 1);
        localStorage.setItem("selectMonth", JSON.stringify(a));
        this.setState({
            selectMonth: a
        });
        this.DateHeader();
    };

    render() {
        const {selectMonth} = this.state;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 col-xlg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="fc-toolbar fc-header-toolbar">
                                    <div className="fc-left">
                                        <button type="button"
                                                className="fc-prev-button fc-button fc-state-default fc-corner-left"
                                                onClick={this.btn_prev_month}><span
                                            className="mdi mdi-chevron-left"></span></button>
                                    </div>
                                    <div className="fc-center">
                                        <h2 id="date_header" className="text-info2">{this.DateHeader()}</h2>
                                    </div>
                                    <div className="fc-right">
                                        <button type="button"
                                                className="fc-next-button fc-button fc-state-default fc-corner-right"
                                                onClick={this.btn_next_month}><span
                                            className="mdi mdi-chevron-right"></span></button>
                                    </div>
                                    <div className="fc-clear"></div>
                                </div>
                                <UserLeave selectMonth={selectMonth} email={"all"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}