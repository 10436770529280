import React from "react";
import AuthService from "../services/auth.service";
import IUser from "../types/user.type";
import UserHome from "../component/user.home";
import AttendanceService from "../services/attendance.service";
import UserService from "../services/user.service";
import IAttendance from "../types/attendance.type";
import moment from "moment";
import UserLeave from "../component/user.leave";

type Props = {};

type State = {
    isModerator: boolean,
    isAdmin: boolean,
    currentUser: IUser,
    selectMonth: Date,
    date: Date,
    queryUser: IUser,
    queryEmail: string,
    curAttendance: IAttendance
}

export default class User extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isModerator: false,
            isAdmin: false,
            currentUser: {} as IUser,
            selectMonth: this.GetStartMonth({}),
            date: new Date(),
            queryUser: {} as IUser,
            queryEmail: "",
            curAttendance: {} as IAttendance
        };
    }

    componentDidMount = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const email = queryParams.get("email");
        const user = AuthService.getCurrentUser();
        if (user.roles != "ROLE_ADMIN") {
            window.location.replace("/");
            return;
        }
        let now = new Date();
        UserService.getUser(email!).then((u: IUser) => {
            AttendanceService.getAttendance(email!, now.getDate(), now.getMonth() + 1, now.getFullYear()).then(
                (a: IAttendance) => {
                    this.setState({
                        curAttendance: a,
                        currentUser: user,
                        queryUser: u,
                        selectMonth: this.GetStartMonth(u),
                        isModerator: user.roles == "ROLE_MODERATOR",
                        isAdmin: user.roles == "ROLE_ADMIN",
                        queryEmail: email as string
                    });
                }
            );
        });
    };

    Clock = () => {
        let x = new Date();
        const x1 = ("0" + x.getHours()).slice(-2) + ":" + ("0" + x.getMinutes()).slice(-2) + ":" + ("0" + x.getSeconds()).slice(-2);
        const x2 = x.toLocaleDateString("en-US", {weekday: 'long', month: 'short', day: '2-digit'});
        return (
            <div>
                <div>
                    <h1 id='ct'>{x1}</h1>
                    <h4 id='ct2'>{x2}</h4>
                </div>
            </div>
        );
    };

    DateHeader = () => {
        let {selectMonth} = this.state;
        // console.log(selectMonth);
        /*let a = new Date(selectMonth.getFullYear(), selectMonth.getMonth(), 1);
        this.setState({
            selectMonth: a
        });*/
        return (
            selectMonth.toLocaleDateString("en-US", {month: 'long', year: 'numeric'})
        );
    };

    GetThisMonth = () => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getDate() > 25 ? (now.getMonth() + 1) : now.getMonth(), now.getDate());
    };

    GetStartMonth = (queryUser: IUser) => {
        if (queryUser != null && queryUser.resign_date != null) {
            const resign = new Date(queryUser.resign_date.replace(/-/g, "/"));
            resign.setDate(1);
            return resign;
        }
        return this.GetThisMonth();
    };

    btn_prev_month = () => {
        const {selectMonth, queryUser} = this.state;
        if (selectMonth.getFullYear() == 2022 && selectMonth.getMonth() <= 2)
            return;
        if (queryUser.date_of_joining != null) {
            const join = new Date(queryUser.date_of_joining.replace(/-/g, "/"));
            if (join.getDate() > 25)
                join.setMonth(join.getMonth() + 1);
            if (join >= selectMonth)
                return;
        }
        let a = new Date(selectMonth.getFullYear(), selectMonth.getMonth() - 1, 1);
        this.setState({
            selectMonth: a
        });
        this.DateHeader();
    };

    btn_next_month = () => {
        const {selectMonth, queryUser} = this.state;
        if (selectMonth >= this.GetThisMonth())
            return;
        if (queryUser.resign_date != null) {
            const resign = new Date(queryUser.resign_date.replace(/-/g, "/"));
            resign.setMonth(resign.getMonth() - 1);
            resign.setDate(1);
            if (selectMonth > resign)
                return;
        }
        let a = new Date(selectMonth.getFullYear(), selectMonth.getMonth() + 1, 1);
        this.setState({
            selectMonth: a
        });
        this.DateHeader();
    };

    ShowPayslip = (email: string) => {
        window.location.replace("/payslip?email=" + email);
    };

    ShowButton = () => {
        const {curAttendance} = this.state;
        if (curAttendance == null) {
            return (
                <div className="m-t-10 m-b-20 waves-effect waves-dark btn btn-primary btn-md btn-rounded">Absent</div>);
        } else if (curAttendance.clockout == null || curAttendance.clockout.trim() == "" || curAttendance.clockout.trim().length == 0) {
            return (<div className="m-t-10 m-b-20 waves-effect waves-dark btn btn-danger btn-md btn-rounded">Check
                In {curAttendance.clockin == null ? "" : moment(new Date(curAttendance.clockin!.replace(/-/g, "/"))).format("HH:mm")}</div>);
        } else {
            return (<div
                className="m-t-10 m-b-20 waves-effect waves-dark btn btn-success btn-md btn-rounded">Leave {moment(new Date(curAttendance.clockout!.replace(/-/g, "/"))).format("HH:mm")}</div>);
        }
    };

    render() {
        const {queryUser, selectMonth, queryEmail, isAdmin} = this.state;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-xlg-3 col-md-5">
                        <div className="card">
                            <div className="card-body">
                                <div className="m-t-20 text-center">
                                    <img src={queryUser?.picture} alt={queryUser?.picture} className="img-circle"
                                         width="150"/>
                                    <h4 className="card-title m-t-10">{queryUser?.name}</h4>
                                    <h5 className="card-subtitle">{queryUser?.title}</h5>
                                    <div className="card-subtitle">
                                        {queryUser?.email}
                                        <br/>
                                        P: {queryUser?.phone}
                                        <br/>
                                        B: {queryUser?.birthday}
                                        <br/>
                                        G: {queryUser?.gender}
                                        <br/>
                                        A: {queryUser?.address}
                                        <br/>
                                        DJ: {queryUser?.date_of_joining}
                                        <br/>
                                        BA: {queryUser?.bank_account}
                                        <br/>
                                        BN: {queryUser?.bank_name}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <hr/>
                            </div>
                            <div className="card-body text-center">
                                {this.ShowButton()}
                                {isAdmin && <button type="button" onClick={() => this.ShowPayslip(queryUser?.email!)}
                                                    className="m-t-10 m-b-20 waves-effect waves-dark btn btn-primary btn-md btn-rounded">Payslip</button>}
                            </div>
                        </div>
                        <UserLeave selectMonth={selectMonth} email={queryUser?.email!}/>
                    </div>
                    <div className="col-lg-8 col-xlg-9 col-md-7">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="bg-light-warning p-l-20 p-r-20">
                                    <div className="fc-toolbar fc-header-toolbar">
                                        <div className="fc-left">
                                            <button type="button"
                                                    className="fc-prev-button fc-button fc-state-default fc-corner-left"
                                                    onClick={this.btn_prev_month}><span
                                                className="mdi mdi-chevron-left"></span></button>
                                        </div>
                                        <div className="fc-center">
                                            <h2 id="date_header" className="text-info2">{this.DateHeader()}</h2>
                                        </div>
                                        <div className="fc-right">
                                            <button type="button"
                                                    className="fc-next-button fc-button fc-state-default fc-corner-right"
                                                    onClick={this.btn_next_month}><span
                                                className="mdi mdi-chevron-right"></span></button>
                                        </div>
                                        <div className="fc-clear"></div>
                                    </div>
                                </div>
                                <UserHome selectMonth={selectMonth} queryUser={queryUser} time={"month"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}