import React from "react";
import AuthService from "../services/auth.service";
import IUser from "../types/user.type";
import UserAttendance from "../component/user.attendance";
import {useNavigate} from "react-router";
import moment from "moment";
import IEvent from "../types/event.type";
import EventService from "../services/event.service";

type Props = {};

type State = {
    isModerator: boolean,
    isAdmin: boolean,
    currentUser: IUser,
    selectDay: Date,
    allEvents: IEvent[]
}

export default class AdminAttendance extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isModerator: false,
            isAdmin: false,
            currentUser: {} as IUser,
            selectDay: this.GetThisDay(),
            allEvents: [] as IEvent[],
        };
    }

    componentDidMount = () => {
        const user = AuthService.getCurrentUser();
        if (user == null || user.roles != "ROLE_ADMIN") {
            window.location.replace("/");
            return;
        }
        if (user) {
            this.setState({
                currentUser: user,
                selectDay: this.GetThisDay(),
                isModerator: user.roles == "ROLE_MODERATOR",
                isAdmin: user.roles == "ROLE_ADMIN",
            });
        }
        EventService.getAllEvents(this.state.selectDay.getMonth() + 1, this.state.selectDay.getFullYear()).then(
            (a: IEvent[]) => {
                this.setState({
                    allEvents: a
                });
            }
        );
    };

    DateHeader = () => {
        const {selectDay} = this.state;
        return moment(selectDay).format("ddd, DD MMMM YYYY");
    };

    GetThisDay = () => {
        const now = new Date();
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        now.setMilliseconds(0);
        return now;
    };

    btn_prev_day = () => {
        const {selectDay} = this.state;
        let a = new Date(selectDay.getFullYear(), selectDay.getMonth(), selectDay.getDate() - 1);
        if (a.getDay() == 0) {
            a = new Date(a.getFullYear(), a.getMonth(), a.getDate() - 1);
        }
        let weekNumber = Number.parseInt(moment(a).format("w"));
        if (a.getFullYear() == 2023)
            weekNumber += 1;
        let replaceWD = false;//currentDate.getFullYear() == 2022 && currentDate.getMonth() == 3 && currentDate.getDate() == 2;
        let isDayOff = false;
        this.state.allEvents?.map(event => {
            const eDate = new Date(event.start_str!);
            if (event.type == "day_off" && event.is_day_off == 0 && a.getFullYear() == eDate.getFullYear() && a.getMonth() == eDate.getMonth() && a.getDate() == eDate.getDate()) {
                replaceWD = true;
            }
            const eDateEnd = new Date(event.end_str!);
            if (event.is_day_off == 1 && eDate <= a && a <= eDateEnd) {
                isDayOff = true;
            }
        });
        if ((a.getDay() == 6 && weekNumber % 2 == 0 && !replaceWD) || isDayOff) {
            a = new Date(a.getFullYear(), a.getMonth(), a.getDate() - 1);
        }
        localStorage.setItem("selectDay", JSON.stringify(a));
        this.setState({
            selectDay: a
        });
        this.DateHeader();
    };

    btn_next_day = () => {
        const {selectDay} = this.state;
        let a = new Date(selectDay.getFullYear(), selectDay.getMonth(), selectDay.getDate() + 1);
        let weekNumber = Number.parseInt(moment(a).format("w"));
        if (a.getFullYear() == 2023)
            weekNumber += 1;
        let replaceWD = false;//currentDate.getFullYear() == 2022 && currentDate.getMonth() == 3 && currentDate.getDate() == 2;
        let isDayOff = false;
        this.state.allEvents?.map(event => {
            const eDate = new Date(event.start_str!);
            if (event.type == "day_off" && event.is_day_off == 0 && a.getFullYear() == eDate.getFullYear() && a.getMonth() == eDate.getMonth() && a.getDate() == eDate.getDate()) {
                replaceWD = true;
            }
            const eDateEnd = new Date(event.end_str!);
            if (event.is_day_off == 1 && eDate <= a && a <= eDateEnd) {
                isDayOff = true;
            }
        });
        if ((a.getDay() == 6 && weekNumber % 2 == 0 && !replaceWD) || isDayOff) {
            a = new Date(a.getFullYear(), a.getMonth(), a.getDate() + 1);
        }
        if (a.getDay() == 0) {
            a = new Date(a.getFullYear(), a.getMonth(), a.getDate() + 1);
        }
        localStorage.setItem("selectDay", JSON.stringify(a));
        this.setState({
            selectDay: a
        });
        this.DateHeader();
    };

    render() {
        const {selectDay} = this.state;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 col-xlg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="tab-content">
                                    <div className="tab-pane active" id="today" role="tabpanel">
                                        <div className="fc-toolbar fc-header-toolbar">
                                            <div className="fc-left">
                                                <button type="button"
                                                        className="fc-prev-button fc-button fc-state-default fc-corner-left"
                                                        onClick={this.btn_prev_day}><span
                                                    className="mdi mdi-chevron-left"></span></button>
                                            </div>
                                            <div className="fc-center">
                                                <h2 id="date_header"
                                                    className="text-info2">{this.DateHeader()}</h2>
                                            </div>
                                            <div className="fc-right">
                                                <button type="button"
                                                        className="fc-next-button fc-button fc-state-default fc-corner-right"
                                                        onClick={this.btn_next_day}><span
                                                    className="mdi mdi-chevron-right"></span></button>
                                            </div>
                                            <div className="fc-clear"></div>
                                        </div>
                                        <UserAttendance selectMonth={selectDay} email={"all"} time={"today"}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}