import React from "react";
import IUser from "../types/user.type";
import AuthService from "../services/auth.service";

type Props = {};

type State = {
    isLoggedIn: boolean,
    currentUser: IUser,
    date: Date
};


export default class Lobby extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoggedIn: false,
            currentUser: {},
            date: new Date()
        };
    }

    componentDidMount = () => {
        const user = AuthService.getCurrentUser();
        if (user) {
            AuthService.login(user).then(
                user => {
                    if (user == null || user.active != 1) {
                        return;
                    }
                    window.location.reload();
                    console.log("login success");
                }
            );
        }
    };

    render() {
        return (
            <>
                <section id="wrapper">
                    <div className="login-register"
                         style={{backgroundImage: `url(${"assets/images/background/login-register.jpg"})`}}>
                        <div className="login-box card">
                            <div className="card-body">
                                <div className="col-xs-12 col-sm-12 col-md-12 m-t-10 text-center">
                                    <img src="assets/images/logo.jpg" alt="assets/images/logo.jpg"
                                         className="img-circle" width="150"/>
                                    <div className="text-center m-t-20 align-self-center">
                                        <h4>Your account is under review!</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}