import React from "react";
import IUser from "../types/user.type";
import AuthService from "../services/auth.service";
import PayService from "../services/pay.service";
import IPayslip from "../types/payslip.type";

type Props = {
    selectMonth: Date
}

type State = {
    isModerator: boolean,
    isAdmin: boolean,
    currentUser: IUser,
    selectMonth: Date,
    payslips: IAllPayslip
}

interface IDate {
    date?: Date,
    holiday?: boolean,
    paid_holiday?: boolean
}

interface IAllPayslip {
    totalEarnings: number,
    totalDeduction: number,
    totalNetIncome: number,
    totalAmercement: number,
    totalCheckInLate: number,
    totalForgotCheckInOut: number,
    allPayslips: IPayslip[],
}

export default class AdminAmercementComponent extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isModerator: false,
            isAdmin: false,
            currentUser: {} as IUser,
            selectMonth: this.props.selectMonth,
            payslips: {} as IAllPayslip,
        };
    }

    componentDidMount = () => {
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
                currentUser: user,
                isModerator: user.roles?.includes("ROLE_MODERATOR"),
                isAdmin: user.roles?.includes("ROLE_ADMIN"),
            });
        }
    };

    componentDidUpdate = () => {
        if (this.state.selectMonth != this.props.selectMonth) {
            this.setState({
                selectMonth: this.props.selectMonth,
                payslips: {} as IAllPayslip,
            });
            this.updateData();
        }
    };

    updateData() {
        PayService.getAmercements(this.props.selectMonth.getMonth() + 1, this.props.selectMonth.getFullYear()).then(
            (a: IAllPayslip) => {
                this.setState({
                    payslips: a
                });
            }
        );
    };

    CreateLink = (isAdmin: boolean, email: string, picture: string, pos: number) => {
        if (isAdmin) {
            return (
                <div className="overlay-bottom">
                    <a href={"/payslip?email=" + email}>
                        <img src={picture} alt="user" className="img-circle overlay-bottom" width="40"/>
                        {pos == 1 && <img className="overlay-top" src="assets/images/crown.png" width="60"/>}
                    </a>
                </div>);
        } else {
            return (<div className="overlay-bottom">
                <img src={picture} alt="user" className="img-circle overlay-bottom" width="40"/>
                {pos == 1 && <img className="overlay-top" src="assets/images/crown.png" width="60"/>}
            </div>);
        }
    };

    CurrencyFormat = (num: number) => {
        return new Intl.NumberFormat('vi-VN', {style: 'currency', currency: 'VND'}).format(num);
    };

    ShowHeader() {
        return (<tr>
            <th className="footable-visible footable-sortable"></th>
            <th className="footable-visible footable-sortable"> Name</th>
            <th className="footable-visible footable-sortable"> Check In Late</th>
            <th className="footable-visible footable-sortable"> Absent/Forgot Check In/Out/Early</th>
            <th className="footable-visible footable-sortable"> Amercement</th>
        </tr>)
    };

    ShowTable() {
        const {payslips, isAdmin} = this.state;
        const p = payslips['allPayslips'];
        if (p != null) {
            let i = 1;
            return p
                .sort((a, b) => Number(b.amercement!) > Number(a.amercement!) ? 1 : -1)
                .map((att) => (
                    <tr key={att.user!.id}>
                        <td>{this.CreateLink(isAdmin, att.user!.email!, att.user!.picture!, i)}</td>
                        <td>{i++}. {att.user!.name}</td>
                        <td>{att.check_in_late!} min</td>
                        <td>{Number(att.forgot_check_in_out!) + Number(att.absent_day!) + Number(att.request_decline_amercement!) + Number(att.check_out_early_amercement!)}</td>
                        <td>{this.CurrencyFormat(Number(att.amercement!))}</td>
                    </tr>
                ))
        }
    };

    render() {
        const {payslips} = this.state;
        return (
            <>
                <div className="card-group">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/chronometer.png" alt="assets/images/chronometer.png"
                                         width="40"/></div>
                                <div>
                                    <h3 className="card-title">{this.CurrencyFormat(payslips.totalAmercement)}</h3>
                                    <h6 className="card-subtitle">Total Amercement</h6></div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/finance.png"
                                         alt="assets/images/finance.png" width="40"/></div>
                                <div>
                                    <h3 className="card-title">{payslips.totalCheckInLate} min</h3>
                                    <h6 className="card-subtitle">Check In Late</h6></div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/sand-clock.png"
                                         alt="assets/images/sand-clock.png" width="40"/></div>
                                <div>
                                    <h3 className="card-title">{payslips.totalForgotCheckInOut} times</h3>
                                    <h6 className="card-subtitle">Forgot Check In/Out/Early</h6></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table m-b-0 toggle-arrow-tiny color-table muted-table">
                        <thead>
                        {this.ShowHeader()}
                        </thead>
                        <tbody>
                        {this.ShowTable()}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}