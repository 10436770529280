import React from "react";
import IUser from "../types/user.type";
import AuthService from "../services/auth.service";

type Props = {};

type State = {
    isModerator: boolean,
    isAdmin: boolean,
    currentUser: IUser,
}

export default class LeftSide extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isModerator: false,
            isAdmin: false,
            currentUser: {} as IUser,
        };
    }

    componentDidMount = () => {
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
                currentUser: user,
                isModerator: user.roles === "ROLE_MODERATOR",
                isAdmin: user.roles === "ROLE_ADMIN",
            });
        }
    }

    render() {
        const {isAdmin} = this.state;
        return (
            <aside className="left-sidebar">
                <div className="scroll-sidebar">
                    <nav className="sidebar-nav">
                        <ul id="sidebarnav">
                            <li className="nav-devider"></li>
                            <li><a className="waves-effect waves-dark" href="/" aria-expanded="false"><i
                                className="mdi mdi-gauge"></i>Overview</a></li>
                            <li><a className="waves-effect waves-dark" href="payslip" aria-expanded="false"><i
                                className="mdi mdi-cash-multiple"></i>Payslip</a></li>
                            <li><a className="waves-effect waves-dark" href="calendar" aria-expanded="false"><i
                                className="mdi mdi-calendar"></i>Calendar</a></li>
                            <li><a className="waves-effect waves-dark" href="contact" aria-expanded="false"><i
                                className="mdi mdi-contacts"></i>Contact</a></li>
                            <li><a className="waves-effect waves-dark" href="profile" aria-expanded="false"><i
                                className="mdi mdi-account-circle"></i>Profile</a></li>
                            <li><a className="waves-effect waves-dark" href="amercement" aria-expanded="false"><i
                                className="mdi mdi-clock-alert"></i>Amercement</a></li>
                            <li><a className="waves-effect waves-dark" href="rules" aria-expanded="false"><i
                                className="mdi mdi-clipboard-outline"></i>Rules</a></li>
                            {isAdmin ?
                                <li><a className="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"><i
                                    className="mdi mdi-settings"></i><span className="hide-menu">Admin</span></a>
                                    <ul aria-expanded="false" className="collapse">
                                        <li><a href="admin.attendance">Attendance</a></li>
                                        <li><a href="admin.leave">Leave</a></li>
                                        <li><a href="admin.payroll">Payroll</a></li>
                                        <li><a href="admin.payslip">Payslip</a></li>
                                        <li><a href="admin.setting">Setting</a></li>
                                    </ul>
                                </li> : <></>}
                        </ul>
                    </nav>
                </div>
            </aside>
        )
    }
}