import React from "react";
import AuthService from "../services/auth.service";
import IUser from "../types/user.type";
import {isIP} from 'is-ip';
import {toast, ToastContainer} from "react-toastify";

type Props = {};

type State = {
    isModerator: boolean,
    isAdmin: boolean,
    currentUser: IUser,
    IPs: string[],
    newIP: string
}

export default class AdminSetting extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isModerator: false,
            isAdmin: false,
            currentUser: {} as IUser,
            IPs: [] as string[],
            newIP: ""
        };

        this.handleAddIP = this.handleAddIP.bind(this);
    }

    componentDidMount = () => {
        const user = AuthService.getCurrentUser();
        if (user == null || user.roles != "ROLE_ADMIN") {
            window.location.replace("/");
            return;
        }
        if (user) {
            this.setState({
                currentUser: user,
                isModerator: user.roles == "ROLE_MODERATOR",
                isAdmin: user.roles == "ROLE_ADMIN",
            });
        }

        this.getIP();
    };

    getIP = async () => {
        if (this.state.IPs.length <= 0) {
            AuthService.getIp().then(
                (result) => {
                    const allowIps = result['allow_ips'] as string[];
                    this.setState({
                        IPs: allowIps,
                        newIP: result['ip']
                    });
                }
            );
        }
    };

    handleAddIP(event: React.ChangeEvent<HTMLInputElement>) {
        let ip = event.target.value;
        this.setState({
            newIP: ip
        });
    };

    ShowAllowIPs = () => {
        const {IPs} = this.state;
        if (IPs != null) {
            return IPs.reverse().map((att, i) => (
                <div>
                    <div className="card-body p-0">
                        <h4 className="card-subtitle">{att}</h4>
                    </div>
                    <hr/>
                </div>
            ))
        }
    };

    AddNewIP = () => {
        if (!isIP(this.state.newIP)) {
            this.ShowToast("Not a valid IP address!");
            return;
        }
        AuthService.addNewIp(this.state.newIP).then(
            (result) => {
                window.location.reload();
            }
        );
    };

    ShowToast = (message: string) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="row">
                    <div className="col-lg-3 col-xlg-2 col-md-4">
                        <div className="card">
                            <div className="card-header text-center">
                                <div className="d-flex text-center">
                                    <div className="m-r-20 align-self-center">
                                        <img src="assets/images/clockin.png" alt="assets/images/clockin.png"
                                             width="25"/>
                                    </div>
                                    <h4 className="text-center m-t-10">Allow IPs</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-8">
                                            <input type="text" value={this.state.newIP} onChange={this.handleAddIP}
                                                   className="form-control form-control-line" name="phone" id="phone"/>
                                        </div>
                                        <div className="col-4">
                                            <button className="btn btn-success" type="submit"
                                                    onClick={() => this.AddNewIP()}>Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {this.ShowAllowIPs()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}